export default {
  type: "roles",
  name: null,
  excerpt: null,
  relationshipNames: ["permissions", "organization", "reseller"],
  permissions: [],
  organization: {
    type: "organizations",
    id: null,
  },
  reseller: {
    type: "resellers",
    id: null,
  },
};
