export default {
  USERS_LIST: "Liste des utilisateurs",
  ADD_USER: "Ajouter un utilisateur",
  USER_ADDED: "Utilisateur ajouté",
  IS_STAFF: "Admin",
  ALL_IS_STAFF: "Tous types",
  AUTHORIZATIONS: "Autorisations",
  EDIT_USER: "Modifier l'utilisateur",
  USER_UPDATED: "Utilisateur modifié",
  DELETE_THIS_USER: "Supprimer cet utilisateur ?",
  USER_DELETED: "Utilisateur supprimé",
  ACTIVE: "Actif",
  ACTIVATE: "Activer",
  DEACTIVATE: "Désactiver",
  USER_ACTIVATED: "Utilisateur Activé",
  USER_DEACTIVATED: "Utilisateur Désactivé",
};
