<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-12">{{ payment.name }}</span>
    </div>

    <div>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.DATE") }}</dt>
        <dd class="col-sm-8">
          {{ payment.date | moment("LLLL") }}
        </dd>
      </dl>
      <dl class="row" v-if="$currentUserCan($permissions.PERM_VIEW_ANY_INVOICES)">
        <dt class="col-sm-4">{{ $t("COMMON.INVOICE") }}</dt>
        <dd class="col-sm-8">
          <router-link
            v-if="payment.invoice"
            :to="`/invoices/view/${payment.invoice.id}`"
          >
            {{ payment.invoice.code }}
          </router-link>
        </dd>
      </dl>

      <dl class="row" v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)">
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <router-link
            v-if="payment.organization"
            :to="`/organizations/view/${payment.organization.id}`"
          >
            {{ payment.organization.name }}
          </router-link>
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
        <dd class="col-sm-8">
          <payment-status-badge :payment="payment" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.AMOUNT") }}</dt>
        <dd class="col-sm-8">
          {{ $formatCurrency(payment.amount) }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("PAYMENTS.SOURCE") }}</dt>
        <dd class="col-sm-8">
          {{ $t(`PAYMENTS.PAYMENT_SOURCE_${payment.source}`) }}
        </dd>
      </dl>

      <dl class="row" v-if="payment.excerpt">
        <dt class="col-sm-4">
          {{ $t("COMMON.EXCERPT") }}
        </dt>
        <dd class="col-sm-8">
          <div v-html="payment.excerpt"></div>
        </dd>
      </dl>

      <dl class="row" v-if="payment.transaction_id">
        <dt class="col-sm-4">
          {{ $t("PAYMENTS.TRANSACTION_ID") }}
        </dt>
        <dd class="col-sm-8">
          {{ payment.transaction_id }}
        </dd>
      </dl>

      <dl class="row" v-if="payment.transaction_data">
        <dt class="col-sm-4">
          {{ $t("PAYMENTS.TRANSACTION_DATA") }}
        </dt>
        <dd class="col-sm-8">
          {{ payment.transaction_data }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="payment.created_at">
          {{ payment.created_at | moment("LLLL") }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="payment.updated_at">
          {{ payment.updated_at | moment("LLLL") }}
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
import PaymentStatusBadge from "./PaymentStatusBadge.vue";
export default {
  name: "payment-view-global",

  components: { PaymentStatusBadge },

  props: ["payment"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    payment(payment) {},
  },
};
</script>
