import defaultTaxes from "@/constants/defaultTaxes";

export default {
  type: "invoice-items",
  code: null,
  excerpt: null,
  unit_price: null,
  quantity: 1,
  discount: 0,
  relationshipNames: ["invoice", "invoiceable"],
  invoice: {
    type: "invoices",
    id: null,
  },
  invoiceable: {
    type: null,
    id: null,
  },
  pricing: {},
  taxes: defaultTaxes,
};
