<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      :label="`${$t('COMMON.INVOICE')} (*)`"
      :placeholder="$t('COMMON.INVOICE')"
      v-if="!invoice"
    >
      <invoice-selector
        :invoice="payment.invoice.id"
        :filterStatus="INVOICE_STATUS_SENT"
        :filterable="true"
        :showAll="false"
        @invoiceChanged="invoiceChanged"
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.invoice" />

    <base-input
      :label="`${$t('COMMON.SOURCE')} (*)`"
      :placeholder="$t('COMMON.SOURCE')"
    >
      <el-select
        name="Source"
        v-model.lazy="payment.source"
        placeholder="Source"
        @change="
          () => {
            onFormChanged();
          }
        "
      >
        <el-option
          v-for="(label, val) in sourcesOption"
          :key="val"
          :value="val"
          :label="label"
        ></el-option>
      </el-select>
      <validation-error :errors="apiValidationErrors.source" />
    </base-input>
    <validation-error :errors="apiValidationErrors.invoice" />

    <base-input :label="$t('COMMON.STATUS')" class="d-none">
      <el-select name="status" v-model="payment.status">
        <el-option
          v-for="(label, val) in statusesOption"
          v-bind:key="val"
          :value="val"
          :label="label"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
      </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.status" />
    <base-input
      :label="`${$t('COMMON.AMOUNT')} (*)`"
      placeholder="amount"
      v-model="payment.amount"
      type="number"
      step="0.0001"
      input-classes="form-control-alternative"
      @change="
        () => {
          onFormChanged();
        }
      "
    >
    </base-input>
    <validation-error :errors="apiValidationErrors.amount" />
    <base-input
      type="textarea"
      :label="$t('COMMON.EXCERPT')"
      :placeholder="$t('COMMON.EXCERPT')"
    >
      <textarea
        class="form-control"
        rows="3"
        v-model="payment.excerpt"
        @change="
          () => {
            onFormChanged();
          }
        "
      ></textarea>
    </base-input>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <div v-if="payment.source != PAYMENT_SOURCE_MANUAL">
      <h2 class="mt-5">{{ $t("PAYMENTS.SOURCE_DETAILS") }}</h2>
      <div class="row">
        <div class="col">
          <base-input
            :label="$t('PAYMENTS.TRANSACTION_ID')"
            v-model="payment.transaction_id"
            @change="
              () => {
                onFormChanged();
              }
            "
          >
          </base-input>
          <validation-error :errors="apiValidationErrors.transaction_id" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <base-input :label="$t('PAYMENTS.TRANSACTION_DATA')">
            <textarea
              class="form-control"
              rows="6"
              v-model="payment.transaction_data"
              @change="
                () => {
                  onFormChanged();
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.transaction_data" />
        </div>
      </div>
    </div>

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          payment.id ? $t("PAYMENTS.EDIT_PAYMENT") : $t("PAYMENTS.ADD_PAYMENT")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import InvoiceSelector from "@/components/InvoiceSelector.vue";
import {
  paymentSourcesOption,
  paymentStatusesOption,
  PAYMENT_SOURCE_MANUAL,
} from "@/constants/payments";
import { INVOICE_STATUS_SENT } from "@/constants/invoices";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    InvoiceSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["paymentData", "formErrors", "loading"],

  data() {
    return {
      payment: { ...this.paymentData },
      statusesOption: paymentStatusesOption,
      sourcesOption: paymentSourcesOption,
      PAYMENT_SOURCE_MANUAL: PAYMENT_SOURCE_MANUAL,
      INVOICE_STATUS_SENT: INVOICE_STATUS_SENT,
      invoice: null,
    };
  },

  created() {
    this.setInvoice();
  },

  methods: {
    async setInvoice() {
      if (this.$route.query.invoice_code) {
        await this.$store.dispatch("invoices/list", {
          filter: { code: this.$route.query.invoice_code },
        });
        const invoices = this.$store.getters["invoices/list"];
        this.invoice = invoices[0];
        this.invoiceChanged(this.invoice.id, this.invoice);
      }
    },

    async handleSubmit() {
      this.$emit("paymentSubmitted", this.payment);
    },

    invoiceChanged(invoiceId, invoice) {
      this.payment.invoice.id = invoiceId;
      this.payment.amount = invoice.total_remaining_payment;
      this.onFormChanged();
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    paymentData(paymentData) {
      if (paymentData) {
        this.payment = { ...this.payment, ...cloneDeep(paymentData) };
      }
    },
  },
};
</script>
