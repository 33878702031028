<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      :label="$t('COMMON.ORGANIZATION')"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <organization-selector
        :organization="role.organization.id"
        :filterable="true"
        :showAll="false"
        :allowNone="true"
        @organizationChanged="
          (organizationId) => {
            role.organization.id = organizationId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="$t('COMMON.RESELLER')"
      :placeholder="$t('COMMON.RESELLER')"
      v-if="
        !role.organization.id &&
        $currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)
      "
    >
      <reseller-selector
        :reseller="role.reseller.id"
        :filterable="true"
        :showAll="false"
        :allowNone="true"
        @resellerChanged="
          (resellerId) => {
            role.reseller.id = resellerId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.reseller" />

    <base-input
      :label="`${$t('COMMON.NAME')} (*)`"
      :placeholder="$t('COMMON.NAME')"
      v-model="role.name"
      @change="onFormChanged"
    />
    <validation-error :errors="apiValidationErrors.name" />

    <base-input
      type="textarea"
      :label="$t('COMMON.EXCERPT')"
      :placeholder="$t('COMMON.EXCERPT')"
    >
      <textarea
        class="form-control"
        rows="3"
        v-model="role.excerpt"
        @change="onFormChanged"
      ></textarea>
    </base-input>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <div class="row mb-5">
      <span class="form-control-label col-12 mb-2">
        {{ `${$t("COMMON.PERMISSIONS")} (*)` }}
      </span>
      <div v-for="model in models" :key="model" class="col-12 row">
        <span class="form-control-label col-12 mb-2 mt-3 text-center">
          {{ $t(`COMMON.${model}`) }}
        </span>
        <div
          v-for="permission in permissionsList.filter(
            (perm) => perm.key.includes(model) && !!permissionsModel[perm.id]
          )"
          :key="permission.key"
          class="col-6"
        >
          <base-checkbox
            v-model="permissionsModel[permission.id].checked"
            @change="onFormChanged"
          >
            {{ $t(`PERMISSIONS.${permission.key}`) }}
          </base-checkbox>
        </div>
      </div>
    </div>
    <validation-error :errors="apiValidationErrors.permissions" />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{ role.id ? $t("ROLES.EDIT_ROLE") : $t("ROLES.ADD_ROLE") }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import models from "@/constants/models";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import ResellerSelector from "@/components/ResellerSelector.vue";
import BaseCheckbox from "@/components/Inputs/BaseCheckbox.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    ResellerSelector,
    BaseCheckbox,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["roleData", "formErrors", "loading"],

  data() {
    return {
      role: { ...this.roleData },
      permissionsList: [],
      permissionsModel: {},
      models: models,
    };
  },

  created() {
    this.getPermissions();
  },

  methods: {
    async getPermissions() {
      try {
        await this.$store.dispatch("permissions/list", {
          sort: "name",
          page: {
            number: 1,
            size: 999999,
          },
        });
        this.permissionsList = await this.$store.getters["permissions/list"];
        for (const permission of this.permissionsList) {
          if (this.role.permissions.find((perm) => perm.id === permission.id)) {
            this.permissionsModel[permission.id] = {
              type: "permissions",
              id: permission.id,
              checked: true,
            };
          } else {
            this.permissionsModel[permission.id] = {
              type: "permissions",
              id: permission.id,
              checked: false,
            };
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async handleSubmit() {
      const permissionsData = [];
      for (const permissionId in this.permissionsModel) {
        if (this.permissionsModel[permissionId].checked) {
          permissionsData.push({ type: "permissions", id: permissionId });
        }
      }
      this.role.permissions = permissionsData;
      this.$emit("roleSubmitted", this.role);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    roleData(roleData) {
      if (roleData) {
        this.role = { ...this.role, ...cloneDeep(roleData) };
        if (!this.role.organization) {
          this.role.organization = {
            type: "organizations",
            id: null,
          };
        }
        if (!this.role.reseller) {
          this.role.reseller = {
            type: "resellers",
            id: null,
          };
        }
        this.getPermissions();
      }
    },
  },
};
</script>
