<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <h1>{{ `${customer.company_name}` }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl
          class="row"
          v-if="customer.customer_type == CUSTOMER_TYPE_INDIVIDUAL"
        >
          <dt class="col-sm-4">{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd class="col-sm-8">
            {{ customer.firstname }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="customer.customer_type == CUSTOMER_TYPE_INDIVIDUAL"
        >
          <dt class="col-sm-4">{{ $t("COMMON.LASTNAME") }}</dt>
          <dd class="col-sm-8">
            {{ customer.lastname }}
          </dd>
        </dl>

        <dl class="row" v-if="customer.customer_type == CUSTOMER_TYPE_COMPANY">
          <dt class="col-sm-4">{{ $t("COMMON.COMPANY_NAME") }}</dt>
          <dd class="col-sm-8">
            {{ customer.company_name }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
          <dd class="col-sm-8">
            {{ customer.email }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.PHONE") }}</dt>
          <dd class="col-sm-8">
            {{ customer.phone }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.COUNTRY") }}</dt>
          <dd class="col-sm-8">
            {{ customer.country }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATE") }}</dt>
          <dd class="col-sm-8">
            {{ customer.state }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CITY") }}</dt>
          <dd class="col-sm-8">
            {{ customer.city }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd class="col-sm-8">
            {{ customer.zipcode }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8">
            {{ customer.address }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <router-link
              v-if="customer.organization"
              :to="`/organizations/view/${customer.organization.id}`"
            >
              {{ `${customer.organization.name}` }}
            </router-link>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ customer.created_at | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ customer.updated_at | moment("LLLL") }}
          </dd>
        </dl>

        <div class="col-12 text-center mb-2 mt-5">
          <h2>{{ $t("INVOICES.BILLING_ADDRESS") }}</h2>
        </div>

        <billing-informations-view :billingInformations="customer" :type="customer.customer_type" />
      </div>
    </div>
  </div>
</template>

<script>
import models from "@/constants/models";
import BillingInformationsView from "@/components/BillingInformationsView.vue";
import {
  CUSTOMER_TYPE_INDIVIDUAL,
  CUSTOMER_TYPE_COMPANY,
} from "@/constants/customers";

export default {
  name: "customer-view-global",

  components: { BillingInformationsView },

  props: ["customer"],

  data() {
    return {
      CUSTOMER_TYPE_COMPANY: CUSTOMER_TYPE_COMPANY,
      CUSTOMER_TYPE_INDIVIDUAL: CUSTOMER_TYPE_INDIVIDUAL,
      models: models,
      permissions: [],
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    customer(customer) {},
  },
};
</script>
