<template>
  <VuePhoneNumberInput
    color="#fb6340"
    v-model="phoneNumberModel"
    size="lg"
    :required="true"
    :no-example="true"
    @update="onChangePhoneNumber"
    :fetch-country="true"
  />
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import parsePhoneNumber from "libphonenumber-js";

export default {
  name: "phone-number-input",

  components: {
    VuePhoneNumberInput,
  },

  props: {
    phoneNumber: {
      type: String,
      default: null,
      description: "Phone number",
    },
  },

  data() {
    return {
      phoneNumberModel: this.phoneNumber,
    };
  },

  setup() {},

  created() {},

  methods: {
    onChangePhoneNumber(input) {
      if (input.isValid) {
        const phoneParsed = parsePhoneNumber(
          input.phoneNumber,
          input.countryCode
        );
        this.$emit("phoneNumberChanged", phoneParsed.format("E.164"));
      }
    },
  },

  watch: {
    phoneNumber(phoneNumber) {
      this.phoneNumberModel = phoneNumber;
    },
  },
};
</script>
