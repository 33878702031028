var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":((_vm.$t('COMMON.ORGANIZATION')) + " (*)"),"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.product.organization.id,"filterable":true,"showAll":false},on:{"organizationChanged":function (organizationId) {
          _vm.product.organization.id = organizationId;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),_c('image-selector',{attrs:{"label":_vm.$t('COMMON.PICTURE'),"defaultImage":_vm.product.picture,"ressource_name":"products","ressource_id":_vm.product.id ? _vm.product.id : 0,"field":"picture"},on:{"imageChanged":function (file_url) {
        _vm.product.picture = file_url;
        _vm.onFormChanged();
      }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.attachment}}),_c('gallery-selector',{attrs:{"label":_vm.$t('COMMON.GALLERY'),"defaultGallery":_vm.product.gallery,"ressource_name":"products","ressource_id":_vm.product.id ? _vm.product.id : 0,"field":"gallery"},on:{"galleryChanged":function (gallery_urls) {
        _vm.product.gallery = gallery_urls;
        _vm.onFormChanged();
      }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.attachment}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.NAME')) + " (*)"),"placeholder":_vm.$t('COMMON.NAME')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.product.name),callback:function ($$v) {_vm.$set(_vm.product, "name", $$v)},expression:"product.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}}),_c('base-input',{attrs:{"type":"textarea","label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.product.excerpt),expression:"product.excerpt"}],staticClass:"form-control",attrs:{"rows":"3"},domProps:{"value":(_vm.product.excerpt)},on:{"change":function($event){return _vm.onFormChanged()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.product, "excerpt", $event.target.value)}}})]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.PRICE')) + " (*)"),"placeholder":_vm.$t('COMMON.PRICE')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.product.price),callback:function ($$v) {_vm.$set(_vm.product, "price", $$v)},expression:"product.price"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.price}}),_c('taxes-selector',{attrs:{"label":_vm.$t('COMMON.TAXES'),"taxes":_vm.product.taxes},on:{"taxesChanged":function (taxes) {
        _vm.product.taxes = taxes;
        _vm.onFormChanged();
      }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.taxes}}),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.product.id ? _vm.$t("PRODUCTS.EDIT_PRODUCT") : _vm.$t("PRODUCTS.ADD_PRODUCT"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }