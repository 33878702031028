<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-12">{{ reseller.name }}</span>
    </div>

    <div class="row">
      <div class="col-9">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ reseller.name }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
          <dd class="col-sm-8">
            {{ reseller.excerpt }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
          <dd class="col-sm-8">
            {{ reseller.email }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.PHONE") }}</dt>
          <dd class="col-sm-8">
            {{ reseller.phone }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8">
            {{ reseller.address }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.OWNER") }}</dt>
          <dd class="col-sm-8">
            <router-link :to="`/users/view/${reseller.owner.id}`">
              {{
                `${reseller.owner.firstname} ${reseller.owner.lastname} - ${reseller.owner.email}`
              }}
            </router-link>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="reseller.created_at">
            {{ reseller.created_at | moment("LLLL") }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="reseller.updated_at">
            {{ reseller.updated_at | moment("LLLL") }}
          </dd>
        </dl>
      </div>
      <div class="col-3">
        <img
          v-if="reseller.logo"
          :src="`${reseller.logo}`"
          class="argon-image"
          style="width: 100%"
        />
        <img v-else src="/img/placeholder.jpg" class="argon-image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reseller-view-global",

  components: {},

  props: ["reseller"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    reseller(reseller) {},
  },
};
</script>
