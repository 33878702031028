<template>
  <el-select
    :placeholder="$t('COMMON.ORGANIZATION')"
    v-model="organizationModel"
    @change="organizationChanged"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getOrganizations"
    :loading="loading"
  >
    <el-option
      v-if="showAll"
      :value="null"
      :label="$t('COMMON.ALL_ORGANIZATIONS')"
    >
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="organization in organizations"
      :key="organization.id"
      :value="organization.id"
      :label="organization.name"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "organization-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    organization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
  },

  data() {
    return {
      organizationModel: this.organization,
      organizations: {},
      loading: false,
    };
  },

  setup() {},

  created() {
    if (this.organization) {
      this.getOrganizations(this.organization.name);
    } else {
      this.getOrganizations("");
    }
  },

  methods: {
    async getOrganizations(query) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { name: query } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterReseller) {
          params = {
            ...params,
            filter: { ...params.filter, reseller: this.filterReseller },
          };
        }

        await this.$store.dispatch("organizations/list", params);
        const organizationsArr = await this.$store.getters[
          "organizations/list"
        ];
        this.organizations = {};
        organizationsArr.forEach((organization) => {
          this.organizations[organization.id] = organization;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    organizationChanged(organization) {
      this.$emit("organizationChanged", organization);
    },
  },

  watch: {
    organization(organization) {
      this.organizationModel = organization;
    },
    filterReseller() {
      this.getOrganizations();
    },
  },
};
</script>
