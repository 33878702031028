<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-10">
              <h3 class="mb-0">
                {{ customer.name }}
                <base-button
                  v-if="$currentUserCan($permissions.PERM_EDIT_CUSTOMERS)"
                  type="primary"
                  icon
                  size="sm"
                  @click="editCustomer()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="$currentUserCan($permissions.PERM_DELETE_CUSTOMERS)"
                  type="danger"
                  icon
                  size="sm"
                  @click="deleteCustomer()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-trash"></i>
                  </span>
                </base-button>
              </h3>
            </div>
            <div class="col-2 text-right">
              <base-button
                @click="goBack()"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
        </template>
        <div>
          <div
            class="
              col-12
              justify-content-center justify-content-sm-between
              flex-wrap
            "
          >
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="global"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="true">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GLOBAL") }}
                  </span>
                  <customer-view-global :customer="customer" />
                </tab-pane>

                <tab-pane
                  title="invoices"
                  id="2"
                  :active="true"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_INVOICES)"
                >
                  <span slot="title">
                    <i class="ni ni-single-copy-04" />
                    {{ $t("COMMON.INVOICES") }}
                  </span>
                  <customer-view-invoices :customer="customer" />
                </tab-pane>

                <tab-pane
                  title="logs"
                  id="999"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
                >
                  <span slot="title">
                    <i class="fa fa-file" />
                    {{ $t("COMMON.LOGS") }}
                  </span>
                  <customer-view-logs :customer="customer" />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { Tabs, TabPane } from "@/components";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import defaultCustomer from "./defaultCustomer";
import { cloneDeep } from "lodash";
import CustomerViewGlobal from "./partials/CustomerViewGlobal.vue";
import CustomerViewInvoices from "./partials/CustomerViewInvoices.vue";
import CustomerViewLogs from "./partials/CustomerViewLogs.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    CustomerViewGlobal,
    CustomerViewInvoices,
    CustomerViewLogs,
  },

  mixins: [],

  data() {
    return {
      customer: cloneDeep(defaultCustomer),
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("customers/get", id);
        this.customer = this.$store.getters["customers/customer"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editCustomer(row) {
      this.$router.push({
        name: "Edit Customer",
        params: { id: this.customer.id },
      });
    },

    async deleteCustomer() {
      const confirmation = await swal.fire({
        title: this.$t("CUSTOMERS.DELETE_THIS_CUSTOMER"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("customers/destroy", this.customer.id);
          await this.goBack();
          this.$notify({
            type: "success",
            message: this.$t("CUSTOMERS.CUSTOMER_DELETED"),
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    goBack() {
      this.$router.push({ name: "List Customers" });
    },
  },
};
</script>
