import permissions from "../constants/permissions";
import AuthenticatedLayout from "@/views/Layouts/Authenticated/AuthenticatedLayout.vue";
import UnAuthenticatedLayout from "@/views/Layouts/UnAuthenticated/UnAuthenticatedLayout.vue";

// UnAuthenticatedPages
import NotFound from "@/views/Pages/UnAuthenticated/NotFoundPage.vue";

const Login = () =>
  import(
    /* webpackChunkName: "auth" */ "@/views/Pages/UnAuthenticated/Login.vue"
  );
const PasswordReset = () =>
  import(
    /* webpackChunkName: "auth" */ "@/views/Pages/UnAuthenticated/Password/Reset.vue"
  );
const PasswordEmail = () =>
  import(
    /* webpackChunkName: "auth" */ "@/views/Pages/UnAuthenticated/Password/Email.vue"
  );

// Authenticated Pages
import ViewProfilePage from "@/views/Pages/Authenticated/UserProfile/ViewProfilePage.vue";
import EditProfilePage from "@/views/Pages/Authenticated/UserProfile/EditProfilePage.vue";

import ListRolePage from "@/views/Pages/Authenticated/RoleManagement/ListRolePage.vue";
import AddRolePage from "@/views/Pages/Authenticated/RoleManagement/AddRolePage.vue";
import EditRolePage from "@/views/Pages/Authenticated/RoleManagement/EditRolePage.vue";
import ViewRolePage from "@/views/Pages/Authenticated/RoleManagement/ViewRolePage.vue";

import ListUserPage from "@/views/Pages/Authenticated/UserManagement/ListUserPage.vue";
import AddUserPage from "@/views/Pages/Authenticated/UserManagement/AddUserPage.vue";
import EditUserPage from "@/views/Pages/Authenticated/UserManagement/EditUserPage.vue";
import ViewUserPage from "@/views/Pages/Authenticated/UserManagement/ViewUserPage.vue";

import ListOrganizationPage from "@/views/Pages/Authenticated/OrganizationManagement/ListOrganizationPage.vue";
import AddOrganizationPage from "@/views/Pages/Authenticated/OrganizationManagement/AddOrganizationPage.vue";
import EditOrganizationPage from "@/views/Pages/Authenticated/OrganizationManagement/EditOrganizationPage.vue";
import ViewOrganizationPage from "@/views/Pages/Authenticated/OrganizationManagement/ViewOrganizationPage.vue";

import ListResellerPage from "@/views/Pages/Authenticated/ResellerManagement/ListResellerPage.vue";
import AddResellerPage from "@/views/Pages/Authenticated/ResellerManagement/AddResellerPage.vue";
import EditResellerPage from "@/views/Pages/Authenticated/ResellerManagement/EditResellerPage.vue";
import ViewResellerPage from "@/views/Pages/Authenticated/ResellerManagement/ViewResellerPage.vue";

import ListProductPage from "@/views/Pages/Authenticated/ProductManagement/ListProductPage.vue";
import AddProductPage from "@/views/Pages/Authenticated/ProductManagement/AddProductPage.vue";
import EditProductPage from "@/views/Pages/Authenticated/ProductManagement/EditProductPage.vue";
import ViewProductPage from "@/views/Pages/Authenticated/ProductManagement/ViewProductPage.vue";

import ListInvoicePage from "@/views/Pages/Authenticated/InvoiceManagement/ListInvoicePage.vue";
import AddInvoicePage from "@/views/Pages/Authenticated/InvoiceManagement/AddInvoicePage.vue";
import EditInvoicePage from "@/views/Pages/Authenticated/InvoiceManagement/EditInvoicePage.vue";
import ViewInvoicePage from "@/views/Pages/Authenticated/InvoiceManagement/ViewInvoicePage.vue";

import ListCustomerPage from "@/views/Pages/Authenticated/CustomerManagement/ListCustomerPage.vue";
import AddCustomerPage from "@/views/Pages/Authenticated/CustomerManagement/AddCustomerPage.vue";
import EditCustomerPage from "@/views/Pages/Authenticated/CustomerManagement/EditCustomerPage.vue";
import ViewCustomerPage from "@/views/Pages/Authenticated/CustomerManagement/ViewCustomerPage.vue";

import ListPackagePage from "@/views/Pages/Authenticated/PackageManagement/ListPackagePage.vue";
import AddPackagePage from "@/views/Pages/Authenticated/PackageManagement/AddPackagePage.vue";
import EditPackagePage from "@/views/Pages/Authenticated/PackageManagement/EditPackagePage.vue";
import ViewPackagePage from "@/views/Pages/Authenticated/PackageManagement/ViewPackagePage.vue";

import ListSubscriptionPage from "@/views/Pages/Authenticated/SubscriptionManagement/ListSubscriptionPage.vue";
import AddSubscriptionPage from "@/views/Pages/Authenticated/SubscriptionManagement/AddSubscriptionPage.vue";
import EditSubscriptionPage from "@/views/Pages/Authenticated/SubscriptionManagement/EditSubscriptionPage.vue";
import ViewSubscriptionPage from "@/views/Pages/Authenticated/SubscriptionManagement/ViewSubscriptionPage.vue";

import ListPaymentPage from "@/views/Pages/Authenticated/PaymentManagement/ListPaymentPage.vue";
import AddPaymentPage from "@/views/Pages/Authenticated/PaymentManagement/AddPaymentPage.vue";
import EditPaymentPage from "@/views/Pages/Authenticated/PaymentManagement/EditPaymentPage.vue";
import ViewPaymentPage from "@/views/Pages/Authenticated/PaymentManagement/ViewPaymentPage.vue";

import ViewLogPage from "@/views/Pages/Authenticated/LogManagement/ViewLogPage.vue";

const Dashboard = () =>
  import(
    /* webpackChunkName: "dashboard" */ "@/views/Pages/Authenticated/Dashboard/Dashboard.vue"
  );

//import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";
import guest from "@/middleware/guest";

let unauthenticatedPages = {
  path: "/",
  component: UnAuthenticatedLayout,
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { middleware: guest },
    },
    {
      path: "/password/reset",
      name: "PasswordReset",
      component: PasswordReset,
      meta: { middleware: guest },
    },
    {
      path: "/password/email",
      name: "PasswordEmail",
      component: PasswordEmail,
      meta: { middleware: guest },
    },
  ],
};

let dashBoardsPages = {
  path: "/",
  component: AuthenticatedLayout,
  children: [
    {
      path: "dashboard",
      name: "Dashboard",
      component: Dashboard,
      meta: { middleware: authenticated },
    },
  ],
};

let userManagementPages = {
  path: "/users",
  component: AuthenticatedLayout,
  children: [
    {
      path: "",
      name: "List Users",
      components: { default: ListUserPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_USERS,
      },
    },
    {
      path: "add",
      name: "Add User",
      components: { default: AddUserPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_USERS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit User",
      components: { default: EditUserPage },
      meta: { middleware: authorized, permission: permissions.PERM_EDIT_USERS },
    },
    {
      path: "view/:id",
      name: "View User",
      components: { default: ViewUserPage },
      meta: { middleware: authorized, permission: permissions.PERM_EDIT_USERS },
    },
  ],
};

let userProfilePages = {
  path: "/me",
  component: AuthenticatedLayout,
  children: [
    {
      path: "",
      name: "View Profile",
      components: { default: ViewProfilePage },
      meta: {},
    },
    {
      path: "change",
      name: "Edit Profile",
      components: { default: EditProfilePage },
      meta: {},
    },
  ],
};

let roleManagementPages = {
  path: "/roles",
  component: AuthenticatedLayout,
  children: [
    {
      path: "",
      name: "List Roles",
      components: { default: ListRolePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_ROLES,
      },
    },
    {
      path: "add",
      name: "Add Role",
      components: { default: AddRolePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_ROLES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Role",
      components: { default: EditRolePage },
      meta: { middleware: authorized, permission: permissions.PERM_EDIT_ROLES },
    },
    {
      path: "view/:id",
      name: "View Role",
      components: { default: ViewRolePage },
      meta: { middleware: authorized, permission: permissions.PERM_VIEW_ROLES },
    },
  ],
};

let organizationManagementPages = {
  path: "/organizations",
  component: AuthenticatedLayout,
  children: [
    {
      path: "",
      name: "List Organizations",
      components: { default: ListOrganizationPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_ORGANIZATIONS,
      },
    },
    {
      path: "add",
      name: "Add Organization",
      components: { default: AddOrganizationPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_ORGANIZATIONS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Organization",
      components: { default: EditOrganizationPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_ORGANIZATIONS,
      },
    },
    {
      path: "view/:id",
      name: "View Organization",
      components: { default: ViewOrganizationPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ORGANIZATIONS,
      },
    },
  ],
};

let productManagementPages = {
  path: "/products",
  component: AuthenticatedLayout,
  children: [
    {
      path: "",
      name: "List Products",
      components: { default: ListProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PRODUCTS,
      },
    },
    {
      path: "add",
      name: "Add Product",
      components: { default: AddProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_PRODUCTS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Product",
      components: { default: EditProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_PRODUCTS,
      },
    },
    {
      path: "view/:id",
      name: "View Product",
      components: { default: ViewProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_PRODUCTS,
      },
    },
  ],
};

let resellerManagementPages = {
  path: "/resellers",
  component: AuthenticatedLayout,
  children: [
    {
      path: "",
      name: "List Resellers",
      components: { default: ListResellerPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_RESELLERS,
      },
    },
    {
      path: "add",
      name: "Add Reseller",
      components: { default: AddResellerPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_RESELLERS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Reseller",
      components: { default: EditResellerPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_RESELLERS,
      },
    },
    {
      path: "view/:id",
      name: "View Reseller",
      components: { default: ViewResellerPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_RESELLERS,
      },
    },
  ],
};

let invoiceManagementPages = {
  path: "/invoices",
  component: AuthenticatedLayout,
  children: [
    {
      path: "",
      name: "List Invoices",
      components: { default: ListInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_INVOICES,
      },
    },
    {
      path: "add",
      name: "Add Invoice",
      components: { default: AddInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_INVOICES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Invoice",
      components: { default: EditInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_INVOICES,
      },
    },
    {
      path: "view/:id",
      name: "View Invoice",
      components: { default: ViewInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_INVOICES,
      },
    },
  ],
};

let customerManagementPages = {
  path: "/customers",
  component: AuthenticatedLayout,
  children: [
    {
      path: "",
      name: "List Customers",
      components: { default: ListCustomerPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CUSTOMERS,
      },
    },
    {
      path: "add",
      name: "Add Customer",
      components: { default: AddCustomerPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_CUSTOMERS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Customer",
      components: { default: EditCustomerPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_CUSTOMERS,
      },
    },
    {
      path: "view/:id",
      name: "View Customer",
      components: { default: ViewCustomerPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_CUSTOMERS,
      },
    },
  ],
};

let packageManagementPages = {
  path: "/packages",
  component: AuthenticatedLayout,
  children: [
    {
      path: "",
      name: "List Packages",
      components: { default: ListPackagePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CUSTOMERS,
      },
    },
    {
      path: "add",
      name: "Add Package",
      components: { default: AddPackagePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_CUSTOMERS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Package",
      components: { default: EditPackagePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_CUSTOMERS,
      },
    },
    {
      path: "view/:id",
      name: "View Package",
      components: { default: ViewPackagePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_CUSTOMERS,
      },
    },
  ],
};

let subscriptionManagementPages = {
  path: "/subscriptions",
  component: AuthenticatedLayout,
  children: [
    {
      path: "",
      name: "List Subscriptions",
      components: { default: ListSubscriptionPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CUSTOMERS,
      },
    },
    {
      path: "add",
      name: "Add Subscription",
      components: { default: AddSubscriptionPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_CUSTOMERS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Subscription",
      components: { default: EditSubscriptionPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_CUSTOMERS,
      },
    },
    {
      path: "view/:id",
      name: "View Subscription",
      components: { default: ViewSubscriptionPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_CUSTOMERS,
      },
    },
  ],
};

let paymentManagementPages = {
  path: "/payments",
  component: AuthenticatedLayout,
  children: [
    {
      path: "",
      name: "List Payments",
      components: { default: ListPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CUSTOMERS,
      },
    },
    {
      path: "add",
      name: "Add Payment",
      components: { default: AddPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_CUSTOMERS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Payment",
      components: { default: EditPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_CUSTOMERS,
      },
    },
    {
      path: "view/:id",
      name: "View Payment",
      components: { default: ViewPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_CUSTOMERS,
      },
    },
  ],
};

let logManagementPages = {
  path: "/logs",
  component: AuthenticatedLayout,
  children: [
    {
      path: "view/:id",
      name: "View Log",
      components: { default: ViewLogPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_LOGS,
      },
    },
  ],
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  unauthenticatedPages,
  dashBoardsPages,
  userProfilePages,
  userManagementPages,
  roleManagementPages,
  organizationManagementPages,
  resellerManagementPages,
  invoiceManagementPages,
  customerManagementPages,
  packageManagementPages,
  subscriptionManagementPages,
  paymentManagementPages,
  productManagementPages,
  logManagementPages,
  {
    path: "/not-found",
    component: NotFound,
  },
  {
    path: "**",
    redirect: "/not-found",
  },
];

export default routes;
