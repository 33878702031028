import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

function upload(image, ressource_name, ressouce_id, field) {
  const payload = new FormData();
  payload.append("attachment", image);

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/uploads/${ressource_name}/${ressouce_id}/${field}`,
      payload,
      options
    )
    .then((response) => {
      return response.data.url;
    });
}

export default {
  upload,
};
