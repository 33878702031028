import { BILLING_CUSTOMER_TYPE_INDIVIDUAL } from "@/constants/billingInformations";

export default {
  type: "users",
  firstname: null,
  lastname: null,
  email: null,
  phone: null,
  is_staff: false,
  locale: null,
  password: null,
  password_confirmation: null,
  profile_image: null,
  relationshipNames: ["organization", "reseller", "roles"],
  roles: [
    {
      type: "roles",
      id: null,
    },
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  reseller: {
    type: "resellers",
    id: null,
  },
  billing_customer_type: BILLING_CUSTOMER_TYPE_INDIVIDUAL,
  billing_country: "Canada",
  billing_state: "Quebec",
};
