<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-12">{{ log.name }}</span>
    </div>

    <div class="row">
      <div class="col">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ log.log_name }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
          <dd class="col-sm-8">
            {{ log.description }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CAUSER") }}</dt>
          <dd class="col-sm-8">
            <span v-if="log.causer">
              <router-link :to="`/users/${log.causer.id}/view`">
                {{
                  `${log.causer.lastname} ${log.causer.firstname} - ${log.causer.email}`
                }}
              </router-link>
            </span>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="log.created_at">
            {{ log.created_at | moment("LLLL") }}
          </dd>
        </dl>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <vue-json-pretty
          :collapsed-on-click-brackets="false"
          :data="log.properties"
          :showDoubleQuotes="false"
        >
          <template #nodeKey="{ node, defaultKey }">
            <template>
              <span v-if="isChanged(node)" :class="getClass(node)">
                {{ getSymbol(node) }} {{ defaultKey }}
              </span>
              <span v-else>
                {{ defaultKey }}
              </span>
            </template>
          </template>

          <template #nodeValue="{ node, defaultValue }">
            <template v-if="node">
              <span v-if="isChanged(node)" :class="getClass(node)">
                {{ nodeValue(node, defaultValue) }}
              </span>
              <span v-else>
                {{ nodeValue(node, defaultValue) }}
              </span>
            </template>
          </template>
        </vue-json-pretty>
      </div>
    </div>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

export default {
  name: "log-view-global",

  components: { VueJsonPretty },

  props: ["log"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    isChanged(node) {
      // is is root attribute
      if (node.path.split(".").length < 3) {
        return false;
      }
      if (this.log.description === "created") {
        return true;
      }
      if (this.log.description === "updated") {
        if (node.path.startsWith("root.old.")) {
          const keyPath = node.path.replace("root.old.", "");
          return this.nodeValuesChanged(keyPath);
        }
        if (node.path.startsWith("root.attributes.")) {
          const keyPath = node.path.replace("root.attributes.", "");
          return this.nodeValuesChanged(keyPath);
        }
      }
    },

    getClass(node) {
      if (this.log.description === "created") {
        return "new-line";
      }
      if (this.log.description === "updated") {
        if (node.path.startsWith("root.old.")) {
          const keyPath = node.path.replace("root.old.", "");
          if (this.nodeValuesChanged(keyPath)) {
            return "old-line";
          }
        }
        if (node.path.startsWith("root.attributes.")) {
          const keyPath = node.path.replace("root.attributes.", "");
          if (this.nodeValuesChanged(keyPath)) {
            return "new-line";
          }
        }
      }
    },

    getSymbol(node) {
      if (this.log.description === "created") {
        return "+";
      }
      if (this.log.description === "updated") {
        if (node.path.startsWith("root.old.")) {
          const keyPath = node.path.replace("root.old.", "");
          if (this.nodeValuesChanged(keyPath)) {
            return "-";
          }
        }
        if (node.path.startsWith("root.attributes.")) {
          const keyPath = node.path.replace("root.attributes.", "");
          if (this.nodeValuesChanged(keyPath)) {
            return "+";
          }
        }
      }
    },

    findValueByKeyPath(obj, keyPath) {
      const keys = keyPath.split(".");
      let currentValue = obj;
      for (const key of keys) {
        if (!currentValue.hasOwnProperty(key)) {
          return undefined;
        }
        currentValue = currentValue[key];
      }
      return currentValue;
    },

    nodeValuesChanged(keyPath) {
      const oldValue = this.findValueByKeyPath(
        this.log.properties.old,
        keyPath
      );
      const newValue = this.findValueByKeyPath(
        this.log.properties.attributes,
        keyPath
      );
      return JSON.stringify(oldValue) != JSON.stringify(newValue);
    },

    nodeValue(node, defaultValue) {
      if (["password"].includes(node.key)) {
        return "XXXXXXXXXXXXXXXXXXXX";
      }
      return defaultValue;
    },
  },

  mounted() {},

  watch: {
    log(log) {},
  },
};
</script>

<style>
.new-line {
  color: #13ce66;
}
.old-line {
  color: #f5365c;
}
.vjs-value {
  margin-left: 10px;
}
.vjs-value-string {
  color: #5e72e4;
}
</style>
