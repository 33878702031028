<template>
  <el-select
    :placeholder="$t('COMMON.ROLE')"
    v-model="roleModel"
    @change="roleChanged"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getRoles"
    :loading="loading"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL_ROLES')">
    </el-option>
    <el-option
      v-for="role in roles"
      :key="role.id"
      :value="role.id"
      :label="role.name"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "role-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    role: {
      type: String,
      default: null,
      description: "Role id",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      roleModel: this.role,
      roles: {},
      loading: false,
    };
  },

  setup() {},

  created() {
    if (this.role) {
      this.getRoles(this.role.name);
    } else {
      this.getRoles("");
    }
  },

  methods: {
    async getRoles(query) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { name: query } : {}),
          },
          page: {
            number: 1,
            size: 20,
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.filterReseller) {
          params = {
            ...params,
            filter: { ...params.filter, reseller: this.filterReseller },
          };
        }

        await this.$store.dispatch("roles/list", params);
        const rolesArr = await this.$store.getters["roles/list"];
        this.roles = {};
        rolesArr.forEach((role) => {
          this.roles[role.id] = role;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    roleChanged(role) {
      this.$emit("roleChanged", role);
    },
  },

  watch: {
    role(role) {
      this.roleModel = role;
    },
    filterReseller() {
      this.getRoles();
    },
    filterOrganization() {
      this.getRoles();
    },
  },
};
</script>
