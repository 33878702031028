var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.DASHBOARD'),
          icon: 'ni ni-shop text-primary',
          path: '/dashboard',
        }}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_CUSTOMERS))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.CUSTOMERS'),
          icon: 'ni ni-circle-08 text-primary',
          path: '/customers',
        }}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_INVOICES))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.INVOICES'),
          icon: 'ni ni-single-copy-04 text-primary',
          path: '/invoices',
        }}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_PAYMENTS))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.PAYMENTS'),
          icon: 'ni ni-money-coins text-primary',
          path: '/payments',
        }}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_SUBSCRIPTIONS))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.SUBSCRIPTIONS'),
          icon: 'fa fa-id-card text-primary',
          path: '/subscriptions',
        }}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_PRODUCTS))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.PRODUCTS'),
          icon: 'fa fa-cubes text-primary',
          path: '/products',
        }}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_PACKAGES))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.PACKAGES'),
          icon: 'ni ni-box-2 text-primary',
          path: '/packages',
        }}}):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_ORGANIZATIONS) &&
          !_vm.me.organization
        )?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.ORGANIZATIONS'),
          icon: 'ni ni-building text-primary',
          path: '/organizations',
        }}}):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_RESELLERS) &&
          !_vm.me.reseller
        )?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.RESELLERS'),
          icon: 'ni ni ni-shop text-primary',
          path: '/resellers',
        }}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_USERS))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.USERS'),
          icon: 'ni ni-single-02 text-primary',
          path: '/users',
        }}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_ROLES))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.ROLES'),
          icon: 'ni ni-hat-3 text-primary',
          path: '/roles',
        }}}):_vm._e(),_c('hr',{staticClass:"my-3",staticStyle:{"border-color":"rgba(0, 0, 0, 0.1) currentcolor currentcolor","border-style":"solid none none","border-width":"1px 0px 0px","border-image":"none 100% / 1 / 0 stretch","min-width":"80%","overflow":"visible","box-sizing":"content-box","height":"0px"}})],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }