var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_RESELLERS))?_c('base-input',{attrs:{"label":((_vm.$t('COMMON.RESELLER')) + " (*)"),"placeholder":_vm.$t('COMMON.RESELLER')}},[_c('reseller-selector',{attrs:{"reseller":_vm.pack.reseller.id,"filterable":true,"showAll":false},on:{"resellerChanged":_vm.resellerChanged}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.reseller}}),_c('image-selector',{attrs:{"label":_vm.$t('COMMON.PICTURE'),"defaultImage":_vm.pack.picture,"ressource_name":"packages","ressource_id":_vm.pack.id ? _vm.pack.id : 0,"field":"picture"},on:{"imageChanged":function (file_url) {
        _vm.pack.picture = file_url;
        _vm.onFormChanged();
      }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.attachment}}),_c('gallery-selector',{attrs:{"label":_vm.$t('COMMON.GALLERY'),"defaultGallery":_vm.pack.gallery,"ressource_name":"packages","ressource_id":_vm.pack.id ? _vm.pack.id : 0,"field":"gallery"},on:{"galleryChanged":function (gallery_urls) {
        _vm.pack.gallery = gallery_urls;
        _vm.onFormChanged();
      }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.attachment}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.NAME')) + " (*)"),"placeholder":_vm.$t('COMMON.NAME')},on:{"change":function () {
        _vm.onFormChanged();
      }},model:{value:(_vm.pack.name),callback:function ($$v) {_vm.$set(_vm.pack, "name", $$v)},expression:"pack.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}}),_c('base-input',{attrs:{"type":"textarea","label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.pack.excerpt),expression:"pack.excerpt"}],staticClass:"form-control",attrs:{"rows":"3"},domProps:{"value":(_vm.pack.excerpt)},on:{"change":function () {
          _vm.onFormChanged();
        },"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.pack, "excerpt", $event.target.value)}}})]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.PRICE')) + " (*)"),"placeholder":_vm.$t('COMMON.PRICE')},on:{"change":function () {
        _vm.onFormChanged();
      }},model:{value:(_vm.pack.price),callback:function ($$v) {_vm.$set(_vm.pack, "price", $$v)},expression:"pack.price"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.price}}),_c('base-input',{attrs:{"label":((_vm.$t('PACKAGES.FREQUENCY')) + " (*)"),"placeholder":_vm.$t('PACKAGES.FREQUENCY')},on:{"change":function () {
        _vm.onFormChanged();
      }},model:{value:(_vm.pack.frequency),callback:function ($$v) {_vm.$set(_vm.pack, "frequency", $$v)},expression:"pack.frequency"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.frequency}}),_c('base-input',{attrs:{"label":((_vm.$t('PACKAGES.MAXIMUM_USERS')) + " (*)"),"placeholder":_vm.$t('PACKAGES.MAXIMUM_USERS')},on:{"change":function () {
        _vm.onFormChanged();
      }},model:{value:(_vm.pack.maximum_users),callback:function ($$v) {_vm.$set(_vm.pack, "maximum_users", $$v)},expression:"pack.maximum_users"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.maximum_users}}),_c('base-input',{attrs:{"label":((_vm.$t('PACKAGES.DEFAULT_ROLE')) + " (*)"),"placeholder":_vm.$t('PACKAGES.DEFAULT_ROLE')}},[_c('role-selector',{attrs:{"disabled":!_vm.pack.reseller.id,"role":_vm.pack.default_role.id,"filterable":true,"showAll":false,"filterReseller":_vm.pack.reseller.id},on:{"roleChanged":function (roleId) {
          _vm.pack.default_role.id = roleId;
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.default_role}}),_c('taxes-selector',{attrs:{"label":_vm.$t('COMMON.TAXES'),"taxes":_vm.pack.taxes},on:{"taxesChanged":function (taxes) {
        _vm.pack.taxes = taxes;
        _vm.onFormChanged();
      }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.taxes}}),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.pack.id ? _vm.$t("PACKAGES.EDIT_PACKAGE") : _vm.$t("PACKAGES.ADD_PACKAGE"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }