<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("PAYMENTS.ADD_PAYMENT") }}</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <payment-form
              :loading="loading"
              :paymentData="payment"
              :formErrors="formErrors"
              @paymentSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultPayment from "./defaultPayment";
import PaymentForm from "./partials/PaymentForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    PaymentForm,
  },

  mixins: [alertLeave],

  data() {
    return {
      payment: cloneDeep(defaultPayment),
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    goBack() {
      this.$router.push({ name: "List Payments" });
    },

    viewPayment(payment) {
      this.alertLeave = false;
      this.$router.push({ name: "View Payment", params: { id: payment.id } });
    },

    async handleSubmit(paymentData) {
      this.loading = true;
      try {
        await this.$store.dispatch("payments/add", paymentData);
        this.$notify({
          type: "success",
          message: this.$t("PAYMENTS.PAYMENT_ADDED"),
        });
        const payment = await this.$store.getters["payments/payment"];
        this.viewPayment(payment);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        const payment = await this.$store.getters["payments/payment"];
        if (payment.id) {
          await this.$store.dispatch("payments/destroy", payment.id);
        }
        this.loading = false;
      }
    },
  },
};
</script>
