export default {
  APP_NAME: "WISE Manager",
  EMAIL: "Courriel",
  PASSWORD: "Mot de passe",
  SIGN_IN: "Connexion",
  FORGOT_PASSWORD: "Mot de passe oublié ?",
  PAGE_NOT_FOUND: "La page demandée n'a pas été touvée",
  RETURN_HOME: "Retour a l'accueil",
  WELCOME: "Bienvenue",
  MY_PROFILE: "Mon profil",
  LOGOUT: "Déconnexion",
  DASHBOARD: "Tableau de bord",
  USERS: "Utilisateurs",
  ROLES: "Roles",
  ORGANIZATIONS: "Organizations",
  ORGANIZATION: "Organization",
  PRODUCTS: "Produits",
  PRODUCT: "Produit",
  ALL: "Tout",
  NAME: "Nom",
  ADDRESS: "Adresse",
  PHONE: "Téléphone",
  CREATED_AT: "Crée le",
  SEARCH: "Recherche",
  PER_PAGE: "Par page",
  VIEW: "Voir",
  EDIT: "Modifier",
  DELETE: "Supprimer",
  DISPLAY_FROM_X_TO_X_OF_X_ENTRIES:
    "Affichage de {from} à {to} sur {of} entrées",
  X_LINES_SELECTED: "{count} lignes sélectionnées",
  RETURN_TO_LIST: "Retour à la liste",
  SELECT_IMAGE: "Choisir une image",
  CHANGE_IMAGE: "Changer l'image",
  REMOVE_IMAGE: "Supprimer l'image",
  ROLE: "Role",
  FIRSTNAME: "Prénom",
  LASTNAME: "Nom",
  CONFIRM_PASSWORD: "Confirmer le mot de passe",
  RESELLERS: "Revendeurs",
  RESELLER: "Revendeur",
  LOCALE: "Langue",
  LOCALE_FR: "Francais",
  LOCALE_EN: "Anglais",
  GLOBAL: "Global",
  NONE: "Aucun",
  UPDATED_AT: "Mis a jour le",
  PERMISSIONS: "Permissions",
  ALL_ORGANIZATIONS: "Toutes les organizations",
  ALL_RESELLERS: "Tous les revendeurs",
  ALL_ROLES: "Tous les roles",
  ALL_USERS: "Tous les utilisateurs",
  ALL_PRODUCTS: "Tous les produits",
  EXCERPT: "Description",
  OWNER: "Proprietaire",
  USER: "Utilisateur",
  YES: "Oui",
  NO: "Non",
  OK: "OK",
  PICTURE: "Photo",
  GALLERY: "Gallerie",
  TAXES: "Taxes",
  PRICE: "Prix",
  RATE: "Taux (%)",
  CODE: "Code",
  DEFAULT_TAXES: "Taxes par defaut",
  INVOICES: "Factures",
  INVOICE: "Facture",
  RECIPIENT: "Destinataire",
  COUNTRY: "Pays",
  COUNTRIES: "Pays",
  STATES: "Regions",
  STATE: "Region",
  CITY: "Ville",
  ZIPCODE: "Code postal",
  NOTE: "Note",
  STATUS: "Statut",
  ALL_STATUSES: "Tous les statut",
  AMOUNT: "Montant",
  PAIED: "Payé",
  TOTAL: "Total",
  TOTAL_PAIED: "Total payé",
  CREATED_BY: "Créé par",
  DETAILS: "Détails",
  PAYMENTS: "Paiements",
  UNIT_PRICE: "Prix unitaire",
  SUBTOTAL: "Sous total",
  QUANTITY: "Quantité",
  DISCOUNT: "Réduction",
  REMAINING_PAYMENT_AMOUNT: "Reste à payer",
  DISCOUNT_PERCENT: "Réduction (%)",
  CLOSE: "Fermer",
  CUSTOMER: "Client",
  CUSTOMERS: "Clients",
  LOGS: "Logs",
  CAUSER: "Auteur",
  DATE: "Date",
  GO_BACK: "Retour",
  PACKAGE: "Forfait",
  PACKAGES: "Forfaits",
  ALL_PACKAGES: "Tous les forfaits",
  SUBSCRIPTION: "Inscription",
  SUBSCRIPTIONS: "Inscriptions",
  TYPE: "Type",
  TAX_TYPE_PERCENTAGE: "Pourcentage",
  TAX_TYPE_AMOUNT: "Montant",
  PAYMENT: "Paiement",
  PAYMENTS: "Paiements",
  ALL_INVOICES: "Toutes les factures",
  DISCOUNTS: "Réductions",
  DISCARD_CHANGES_AND_LEAVE_PAGE: "Abandonner les modifications et quitter?",
  COMPANY_NAME: "Nom de l'entreprise",
  BILLING_CUSTOMER_TYPE_INDIVIDUAL: "Particulier",
  BILLING_CUSTOMER_TYPE_COMPANY: "Entreprise",
};
