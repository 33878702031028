<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-12">{{ user.name }}</span>
    </div>

    <div class="row">
      <div class="col">
        <dl class="row" v-for="role of user.roles" :key="role.id">
          <dt class="col-sm-12 text-center mb-5 display-4">
            <router-link
              v-if="user.roles[0]"
              :to="`/roles/view/${user.roles[0].id}`"
            >
              {{ role.name }}
            </router-link>
          </dt>
          <role-permissions :role="role" />
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
import RolePermissions from "@/components/RolePermissions.vue";

export default {
  name: "user-view-authorizations",

  components: { RolePermissions },

  props: ["user"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
