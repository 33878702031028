import { BILLING_CUSTOMER_TYPE_COMPANY } from "@/constants/billingInformations";

export default {
  type: "organizations",
  name: null,
  excerpt: null,
  email: null,
  phone: null,
  address: null,
  logo: null,
  taxes: [],
  activePermissions: [],
  relationshipNames: ["owner", "reseller"],
  owner: {
    type: "users",
    id: null,
  },
  reseller: {
    type: "resellers",
    id: null,
  },
  billing_customer_type: BILLING_CUSTOMER_TYPE_COMPANY,
  billing_country: "Canada",
  billing_state: "Quebec",
};
