<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("INVOICES.ADD_INVOICE") }}</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <invoice-form
              :loading="loading"
              :invoiceData="invoice"
              :formErrors="formErrors"
              @invoiceSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import defaultTaxes from "@/constants/defaultTaxes";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultInvoice from "./defaultInvoice";
import InvoiceForm from "./partials/InvoiceForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    InvoiceForm,
  },

  mixins: [alertLeave],

  data() {
    const invoice = cloneDeep(defaultInvoice);
    invoice.taxes = cloneDeep(defaultTaxes);
    if (this.$currentUserIsLevelOrganization()) {
      invoice.organization = {
        type: "organizations",
        id: this.$currentUserOrganizationId(),
      };
    }
    return {
      invoice: invoice,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    goBack() {
      this.$router.push({ name: "List Invoices" });
    },

    viewInvoice(invoice) {
      this.alertLeave = false;
      this.$router.push({
        name: "View Invoice",
        params: { id: invoice.id },
      });
    },

    async handleSubmit(invoice) {
      this.loading = true;

      const invoiceData = cloneDeep(invoice);
      delete invoiceData.id;
      if (!invoiceData.recipient.id) {
        delete invoiceData.recipient;
      }
      if (!invoiceData.organization.id) {
        delete invoiceData.organization;
      }

      try {
        await this.$store.dispatch("invoices/add", invoiceData);
        this.$notify({
          type: "success",
          message: this.$t("INVOICES.INVOICE_ADDED"),
        });
        const invoice = await this.$store.getters["invoices/invoice"];
        this.viewInvoice(invoice);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
