export default {
  PERM_VIEW_MODULE_PAYMENTS: "Acceder au module paiements",
  PERM_VIEW_ANY_PAYMENTS: "Voir tous les paiements",
  PERM_VIEW_PAYMENTS: "Voir les details d'un paiement",
  PERM_CREATE_PAYMENTS: "Créer un paiement",
  PERM_VIEW_MODULE_SUBSCRIPTIONS: "Acceder au module inscriptions",
  PERM_VIEW_ANY_SUBSCRIPTIONS: "Voir toutes les inscriptions",
  PERM_VIEW_SUBSCRIPTIONS: "Voir les details d'une inscription",
  PERM_CREATE_SUBSCRIPTIONS: "Créer une inscription",
  PERM_EDIT_SUBSCRIPTIONS: "Modifier les inscriptions",
  PERM_DELETE_SUBSCRIPTIONS: "Supprimer une inscription",
  PERM_VIEW_MODULE_PACKAGES: "Acceder au module forfaits",
  PERM_VIEW_ANY_PACKAGES: "Voir toutes les forfaits",
  PERM_VIEW_PACKAGES: "Voir les details d'une forfait",
  PERM_CREATE_PACKAGES: "Créer une forfait",
  PERM_EDIT_PACKAGES: "Modifier les forfaits",
  PERM_DELETE_PACKAGES: "Supprimer une forfait",
  PERM_VIEW_MODULE_CUSTOMERS: "Acceder au module clients",
  PERM_VIEW_ANY_CUSTOMERS: "Voir toutes les clients",
  PERM_VIEW_CUSTOMERS: "Voir les details d'une client",
  PERM_CREATE_CUSTOMERS: "Créer une client",
  PERM_EDIT_CUSTOMERS: "Modifier les clients",
  PERM_DELETE_CUSTOMERS: "Supprimer une client",
  PERM_VIEW_MODULE_INVOICES: "Acceder au module factures",
  PERM_VIEW_ANY_INVOICES: "Voir toutes les factures",
  PERM_VIEW_INVOICES: "Voir les details d'une facture",
  PERM_CREATE_INVOICES: "Créer une facture",
  PERM_EDIT_INVOICES: "Modifier les factures",
  PERM_DELETE_INVOICES: "Supprimer une facture",
  PERM_VIEW_MODULE_PRODUCTS: "Acceder au module produits",
  PERM_VIEW_ANY_PRODUCTS: "Voir toutes les produits",
  PERM_VIEW_PRODUCTS: "Voir les details des produit",
  PERM_CREATE_PRODUCTS: "Créer une produit",
  PERM_EDIT_PRODUCTS: "Modifier les produits",
  PERM_EDIT_OWN_PRODUCTS: "Modifier son produit",
  PERM_DELETE_PRODUCTS: "Supprimer les produits",
  PERM_VIEW_MODULE_ORGANIZATIONS: "Acceder au module organisations",
  PERM_VIEW_ANY_ORGANIZATIONS: "Voir toutes les organisations",
  PERM_VIEW_ORGANIZATIONS: "Voir les details des organisation",
  PERM_CREATE_ORGANIZATIONS: "Créer une organisation",
  PERM_EDIT_ORGANIZATIONS: "Modifier les organisations",
  PERM_EDIT_OWN_ORGANIZATIONS: "Modifier son organisation",
  PERM_DELETE_ORGANIZATIONS: "Supprimer les organisations",
  PERM_VIEW_MODULE_RESELLERS: "Acceder au module revendeurs",
  PERM_VIEW_ANY_RESELLERS: "Voir tout les revendeurs",
  PERM_VIEW_RESELLERS: "Voir les details des revendeurs",
  PERM_CREATE_RESELLERS: "Créer un revendeur",
  PERM_EDIT_RESELLERS: "Modifier les revendeurs",
  PERM_EDIT_OWN_RESELLERS: "Modifier son revendeur",
  PERM_DELETE_RESELLERS: "Supprimer les revendeurs",
  PERM_VIEW_MODULE_USERS: "Acceder au module utilisateurs",
  PERM_VIEW_ANY_USERS: "Voir tout les utilisateurs",
  PERM_VIEW_USERS: "Voir les details des utilisateurs",
  PERM_CREATE_USERS: "Créer un utilisateur",
  PERM_EDIT_USERS: "Modifier les utilisateurs",
  PERM_DELETE_USERS: "Supprimer les utilisateur",
  PERM_VIEW_MODULE_ROLES: "Acceder au module roles",
  PERM_VIEW_ANY_ROLES: "Voir tout les roles",
  PERM_VIEW_ROLES: "Voir les details des roles",
  PERM_CREATE_ROLES: "Créer un role",
  PERM_EDIT_ROLES: "Modifier les roles",
  PERM_DELETE_ROLES: "Supprimer les roles",
  PERM_VIEW_ANY_PERMISSIONS: "Voir toutes les permissions",
  PERM_VIEW_PERMISSIONS: "Voir les details des permissions",
  PERM_VIEW_ANY_LOGS: "Voir tous les logs",
  PERM_VIEW_LOGS: "Voir les details d'un log",
};
