<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">{{ $t("COMMON.DETAILS") }}</span>
      </div>
      <div class="col-4 text-right">
        <base-button
          @click="openInvoiceItemModal()"
          type="button"
          class="btn btn-sm btn-primary"
          v-if="invoice.status === INVOICE_STATUS_DRAFT"
        >
          <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
          {{ $t("INVOICES.ADD_INVOICE_ITEM") }}
        </base-button>
      </div>
    </div>

    <invoice-view-details-invoice-item-table
      :invoice="invoice"
      @onOpenInvoiceItemModal="openInvoiceItemModal"
    />

    <div class="row mb-5" v-if="invoice.pricing">
      <div class="col-6"></div>
      <div class="col-6">
        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.SUBTOTAL") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(invoice.pricing.subtotal) }}
          </dd>
        </dl>

        <dl class="row mb-0" v-if="invoice.pricing.discounts.total">
          <dt class="col-sm-6">{{ $t("COMMON.DISCOUNTS") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(invoice.pricing.discounts.total * -1) }}
          </dd>
          <div class="col-12">
            <dl
              class="row mb-0"
              v-for="(discount, index) in invoice.pricing.discounts.details"
              v-bind:key="index"
            >
              <dt class="col-sm-6 pl-5 text-muted">{{ discount.name }}</dt>
              <dd class="col-sm-6 text-right text-muted">
                {{ $formatCurrency(discount.amount) }}
              </dd>
            </dl>
          </div>
        </dl>

        <dl class="row mb-0" v-if="invoice.pricing.taxes.total">
          <dt class="col-sm-6">{{ $t("COMMON.TAXES") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(invoice.pricing.taxes.total) }}
          </dd>
          <div class="col-12">
            <dl
              class="row mb-0"
              v-for="(tax, index) in invoice.pricing.taxes.details"
              v-bind:key="index"
            >
              <dt class="col-sm-6 pl-5 text-muted">{{ tax.name }}</dt>
              <dd class="col-sm-6 text-right text-muted">
                {{ $formatCurrency(tax.amount) }}
              </dd>
            </dl>
          </div>
        </dl>

        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.TOTAL") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(invoice.pricing.total) }}
          </dd>
        </dl>

        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.TOTAL_PAIED") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(invoice.total_paied) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-6">{{ $t("COMMON.REMAINING_PAYMENT_AMOUNT") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(invoice.total_remaining_payment) }}
          </dd>
        </dl>
      </div>
    </div>

    <!-- Modal add slot -->
    <invoice-view-details-invoice-item-form
      :invoice="invoice"
      :invoiceItem="invoiceItem"
      :showModal="showModal"
      @onCloseInvoiceItemModal="closeInvoiceItemModal"
      @onSubmitInvoiceItemForm="handleInvoiceItemFormSubmit"
      @onDeleteInvoiceItem="deleteInvoiceItem"
    />
  </div>
</template>

<script>
import { Button, Tooltip } from "element-ui";
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DRAFT,
} from "@/constants/invoices";
import InvoiceViewDetailsInvoiceItemForm from "./InvoiceViewDetailsInvoiceItemForm.vue";
import InvoiceViewDetailsInvoiceItemTable from "./InvoiceViewDetailsInvoiceItemTable.vue";
import defaultInvoiceItem from "../defaultInvoiceItem";

export default {
  name: "invoice-view-details",

  components: {
    InvoiceViewDetailsInvoiceItemForm,
    InvoiceViewDetailsInvoiceItemTable,
    [Tooltip.name]: Tooltip,
    [Button.name]: Button,
  },

  mixins: [formMixin],

  props: ["invoice"],

  data() {
    return {
      invoiceItem: cloneDeep(defaultInvoiceItem),
      loading: false,
      showModal: false,
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
      INVOICE_STATUS_CANCELED: INVOICE_STATUS_CANCELED,
    };
  },

  computed: {},

  methods: {
    openInvoiceItemModal(invoiceItem = null) {
      if (!invoiceItem) {
        this.invoiceItem = cloneDeep(defaultInvoiceItem);
      } else {
        this.invoiceItem = cloneDeep(invoiceItem);
      }
      this.showModal = true;
    },

    closeInvoiceItemModal() {
      this.showModal = false;
      this.loading = false;
    },

    handleInvoiceItemFormSubmit(invoiceItemData) {
      if (!invoiceItemData.id) {
        this.addInvoiceItem(invoiceItemData);
      } else {
        this.editInvoiceItem(invoiceItemData);
      }
    },

    async addInvoiceItem(invoiceItemData) {
      this.loading = true;
      try {
        invoiceItemData.invoice.id = this.invoice.id;

        await this.$store.dispatch("invoiceItems/add", invoiceItemData);
        this.$emit("invoiceItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("INVOICES.INVOICE_ITEM_ADDED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async editInvoiceItem(invoiceItemData) {
      this.loading = true;
      try {
        await this.$store.dispatch("invoiceItems/update", invoiceItemData);
        this.$emit("invoiceItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("INVOICES.INVOICE_ITEM_EDITED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async deleteInvoiceItem(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("invoiceItems/destroy", id);
        this.$emit("invoiceItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("INVOICES.INVOICE_ITEM_DELETED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {},
};
</script>
