export default {
  PAYMENTS: "PAYMENTS",
  INVOICES: "INVOICES",
  CUSTOMERS: "CUSTOMERS",
  SUBSCRIPTIONS: "SUBSCRIPTIONS",
  PACKAGES: "PACKAGES",
  PRODUCTS: "PRODUCTS",
  ORGANIZATIONS: "ORGANIZATIONS",
  RESELLERS: "RESELLERS",
  USERS: "USERS",
  ROLES: "ROLES",
  PERMISSIONS: "PERMISSIONS",
  LOGS: "LOGS",
};
