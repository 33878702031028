import { CUSTOMER_TYPE_INDIVIDUAL } from "@/constants/customers";

export default {
  type: "customers",
  customer_type: CUSTOMER_TYPE_INDIVIDUAL,
  firstname: null,
  lastname: null,
  email: null,
  phone: null,
  country: "Canada",
  state: "Quebec",
  city: null,
  zipcode: null,
  address: null,
  relationshipNames: ["organization"],
  organization: {
    type: "organizations",
    id: null,
  },
  billing_customer_type: CUSTOMER_TYPE_INDIVIDUAL,
};
