<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">{{ $t("SUBSCRIPTIONS.SUBSCRIPTIONS_LIST") }}</h3>
            </div>
            <div class="col-6 text-right">
              <base-button
                type="primary"
                icon
                size="sm"
                @click="addSubscription()"
                v-if="$currentUserCan($permissions.PERM_CREATE_SUBSCRIPTIONS)"
              >
                <span class="btn-inner--icon">
                  <i class="fas fa-plus"></i>
                </span>
                <span class="btn-inner--text">{{
                  $t("SUBSCRIPTIONS.ADD_SUBSCRIPTION")
                }}</span>
              </base-button>
            </div>
          </div>
        </template>

        <subscription-list-table />
      </card>
    </div>
  </div>
</template>
<script>
import { Button } from "element-ui";
import SubscriptionListTable from "./partials/SubscriptionListTable.vue";

export default {
  layout: "DashboardLayout",

  components: {
    SubscriptionListTable,
    [Button.name]: Button,
  },

  mixins: [],

  computed: {},

  watch: {},

  methods: {
    addSubscription() {
      this.$router.push({ name: "Add Subscription" });
    },
  },
};
</script>
