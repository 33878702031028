<template>
  <div>
    <div>
      <div
        class="
          col-12
          d-flex
          justify-content-center justify-content-sm-between
          flex-wrap
        "
      >
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <organization-selector
          v-if="
            !filterOrganization &&
            !filterInvoice &&
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
          "
          @organizationChanged="
            (organizationId) => (selectedOrganization = organizationId)
          "
        />

        <invoice-selector
          v-if="!filterInvoice"
          :filterStatus="INVOICE_STATUS_SENT"
          @invoiceChanged="(invoiceId) => (selectedInvoice = invoiceId)"
        />

        <div>
          <base-input
            v-model="query"
            type="search"
            prepend-icon="fas fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
        </div>
      </div>
      <el-table
        class="table-responsive align-items-center table-flush"
        header-row-class-name="thead-light"
        :data="payments"
        @sort-change="sortChange"
      >
        <div slot="empty" v-if="loading">
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </div>
        <el-table-column label="Code" min-width="80px" prop="code" />

        <el-table-column :label="$t('COMMON.DATE')" prop="date" sortable="date">
          <template v-slot="{ row }">
            {{ row.date | moment("LLLL") }}
          </template>
        </el-table-column>

        <el-table-column :label="$t('COMMON.STATUS')" prop="status">
          <template v-slot="{ row }">
            <payment-status-badge :payment="row" />
          </template>
        </el-table-column>

        <el-table-column :label="$t('COMMON.AMOUNT')" prop="amount">
          <template v-slot="{ row }">
            {{ $formatCurrency(row.amount) }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="
            !filterOrganization &&
            !filterInvoice &&
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
          "
          :label="$t('COMMON.ORGANIZATION')"
          prop="organization.name"
        >
          <template v-slot="{ row }">
            <span>
              {{ row.organization ? row.organization.name : null }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          v-if="!filterInvoice"
          :label="$t('COMMON.INVOICE')"
          prop="invoice.code"
        >
          <template v-slot="{ row }">
            <span>
              {{ row.invoice ? row.invoice.code : null }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('COMMON.CREATED_AT')"
          prop="created_at"
          sortable="custom"
        />
        <el-table-column min-width="50px" align="center">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip
              :content="$t('COMMON.VIEW')"
              placement="top"
              v-if="$currentUserCan($permissions.PERM_VIEW_PAYMENTS)"
            >
              <a
                type="text"
                @click="viewPayment(row)"
                class="table-action"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-eye"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div
      slot="footer"
      class="
        mt-5
        col-12
        d-flex
        justify-content-center justify-content-sm-between
        flex-wrap
      "
    >
      <div class="">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}

          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import { BasePagination } from "@/components";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import InvoiceSelector from "@/components/InvoiceSelector.vue";
import PaymentStatusBadge from "./PaymentStatusBadge.vue";
import { INVOICE_STATUS_SENT } from "@/constants/invoices";

export default {
  name: "payment-list-table",

  components: {
    BasePagination,
    OrganizationSelector,
    InvoiceSelector,
    PaymentStatusBadge,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterInvoice: {
      type: String,
      default: null,
      description: "Invoice id",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-date",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      payments: [],
      INVOICE_STATUS_SENT: INVOICE_STATUS_SENT,
      loading: true,
      selectedOrganization: null,
      selectedInvoice: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterInvoice: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedInvoice: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { code: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,invoice",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.filterInvoice) {
          params = {
            ...params,
            filter: { ...params.filter, invoice: this.filterInvoice },
          };
        }
        if (this.selectedInvoice) {
          params = {
            ...params,
            filter: { ...params.filter, invoice: this.selectedInvoice },
          };
        }

        await this.$store.dispatch("payments/list", params);
        this.payments = this.$store.getters["payments/list"];
        this.total = this.$store.getters["payments/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewPayment(payment) {
      this.$router.push({
        name: "View Payment",
        params: { id: payment.id },
      });
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
