<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-checkbox
      v-model="user.is_staff"
      class="mb-3"
      v-if="showIsStaff && $currentUser().is_staff"
      @change="
        () => {
          onFormChanged();
        }
      "
    >
      <span class="form-control-label">{{ $t("USERS.IS_STAFF") }}</span>
    </base-checkbox>
    <validation-error :errors="apiValidationErrors.is_staff" />

    <base-input
      v-if="
        !user.is_staff &&
        showReseller &&
        $currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)
      "
      :label="$t('COMMON.RESELLER')"
      :placeholder="$t('COMMON.RESELLER')"
    >
      <reseller-selector
        :reseller="user.reseller ? user.reseller.id : null"
        :filterable="true"
        :showAll="false"
        :allowNone="true"
        @resellerChanged="
          (resellerId) => {
            user.reseller = { type: 'resellers', id: resellerId };
            user.organization = { type: 'organizations', id: null };
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.reseller" />

    <base-input
      v-if="
        !user.is_staff &&
        showOrganization &&
        $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
      "
      :label="$t('COMMON.ORGANIZATION')"
      :placeholder="$t('COMMON.ORGANIZATION')"
    >
      <organization-selector
        :filterReseller="user.reseller.id"
        :organization="user.organization ? user.organization.id : null"
        :filterable="true"
        :showAll="false"
        :allowNone="true"
        @organizationChanged="
          (organizationId) => {
            user.organization = { type: 'organizations', id: organizationId };
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <image-selector
      :defaultImage="user.profile_image"
      ressource_name="users"
      :ressource_id="user.id ? user.id : 0"
      field="profile_image"
      @imageChanged="
        (file_url) => {
          user.profile_image = file_url;
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.attachment" />

    <base-input
      :label="`${$t('COMMON.FIRSTNAME')} (*)`"
      :placeholder="$t('COMMON.FIRSTNAME')"
      v-model="user.firstname"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.firstname" />

    <base-input
      :label="`${$t('COMMON.LASTNAME')} (*)`"
      :placeholder="$t('COMMON.LASTNAME')"
      v-model="user.lastname"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.lastname" />

    <base-input
      :label="`${$t('COMMON.EMAIL')} (*)`"
      :placeholder="$t('COMMON.EMAIL')"
      v-model="user.email"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.email" />

    <base-input :label="`${$t('COMMON.PHONE')} (*)`">
      <phone-number-input
        :phoneNumber="user.phone"
        @phoneNumberChanged="
          (phone) => {
            user.phone = phone;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.phone" />

    <base-input
      :label="`${$t('COMMON.ROLE')} (*)`"
      :placeholder="$t('COMMON.ROLE')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ROLES)"
    >
      <role-selector
        :role="user.roles.length ? user.roles[0].id : null"
        :filterable="true"
        :showAll="false"
        :filterReseller="user.reseller.id"
        :filterOrganization="user.organization.id"
        @roleChanged="
          (roleId) => {
            user.roles[0].id = roleId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.roles" />

    <base-input
      :label="`${$t('COMMON.LOCALE')} (*)`"
      :placeholder="$t('COMMON.LOCALE')"
    >
      <locale-selector
        :locale="user.locale"
        @localeChanged="
          (locale) => {
            user.locale = locale;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.locale" />

    <base-input
      v-model="user.password"
      type="password"
      name="new_password"
      class="mb-3"
      prepend-icon="fa fa-key"
      :placeholder="$t('COMMON.PASSWORD')"
      :label="$t('COMMON.PASSWORD')"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.password" />

    <base-input
      v-model="user.password_confirmation"
      type="password"
      name="confirm_password"
      autocomplete="on"
      class="mb-3"
      prepend-icon="fa fa-key"
      :placeholder="$t('COMMON.CONFIRM_PASSWORD')"
      :label="$t('COMMON.CONFIRM_PASSWORD')"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.password_confirmation" />

    <h2>{{ $t("INVOICES.BILLING_ADDRESS") }}</h2>

    <billing-informations-form
      :billingInformationsData="user"
      :formErrors="formErrors"
      @onChangeBillingInformations="billingInformationsChanged"
    />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{ user.id ? $t("USERS.EDIT_USER") : $t("USERS.ADD_USER") }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ImageSelector from "@/components/ImageSelector.vue";
import RoleSelector from "@/components/RoleSelector.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import ResellerSelector from "@/components/ResellerSelector.vue";
import BaseCheckbox from "@/components/Inputs/BaseCheckbox.vue";
import LocaleSelector from "@/components/LocaleSelector.vue";
import BillingInformationsForm from "@/components/BillingInformationsForm.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    ImageSelector,
    RoleSelector,
    OrganizationSelector,
    ResellerSelector,
    BaseCheckbox,
    LocaleSelector,
    BillingInformationsForm,
    PhoneNumberInput,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: {
    userData: {
      type: Object,
    },
    formErrors: {
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showReseller: {
      type: Boolean,
      default: true,
    },
    showOrganization: {
      type: Boolean,
      default: true,
    },
    showIsStaff: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      user: { ...this.userData },
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      this.$emit("userSubmitted", this.user);
    },

    billingInformationsChanged(billingInformations) {
      this.user = { ...this.user, ...billingInformations };
      this.onFormChanged();
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    userData(userData) {
      if (userData) {
        this.user = { ...this.user, ...cloneDeep(userData) };
        if (!this.user.organization) {
          this.user.organization = { type: "organizations", id: null };
        }
        if (!this.user.reseller) {
          this.user.reseller = { type: "resellers", id: null };
        }
      }
    },
  },
};
</script>
