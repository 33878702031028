export default {
  type: "products",
  name: null,
  excerpt: null,
  price: null,
  subscribable: false,
  taxes: [],
  picture: null,
  gallery: [],
  relationshipNames: ["organization"],
  organization: {
    type: "organizations",
    id: null,
  },
};
