<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-10">
              <h3 class="mb-0">
                {{ organization.name }}
                <base-button
                  v-if="$currentUserCan($permissions.PERM_EDIT_ORGANIZATIONS)"
                  type="primary"
                  icon
                  size="sm"
                  @click="editOrganization()"
                  :disabled="loading"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="
                    $currentUserCan($permissions.PERM_EDIT_ORGANIZATIONS) &&
                    $currentUserCan($permissions.PERM_CREATE_SUBSCRIPTIONS)
                  "
                  type="info"
                  icon
                  size="sm"
                  @click="showSubscriptionAddModal = true"
                  :disabled="loading"
                >
                  <span class="btn-inner--icon">
                    {{ $t("ORGANIZATIONS.ADD_SUBSCRIPTION") }}
                    <i class="fas fa-plus"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="$currentUserCan($permissions.PERM_DELETE_ORGANIZATIONS)"
                  type="danger"
                  icon
                  size="sm"
                  @click="deleteOrganization()"
                  :disabled="loading"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-trash"></i>
                  </span>
                </base-button>
              </h3>
            </div>
            <div class="col-2 text-right">
              <base-button
                @click="goBack()"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
        </template>
        <div>
          <div
            class="
              col-12
              justify-content-center justify-content-sm-between
              flex-wrap
            "
          >
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="global"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="true">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GLOBAL") }}
                  </span>
                  <organization-view-global :organization="organization" />
                </tab-pane>

                <tab-pane
                  title="authorizations"
                  id="2"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PERMISSIONS)"
                >
                  <span slot="title">
                    <i class="ni ni-key-25" />
                    {{ $t("ORGANIZATIONS.AUTHORIZATIONS") }}
                  </span>
                  <organization-view-authorizations
                    :organization="organization"
                  />
                </tab-pane>

                <tab-pane
                  title="subscriptions"
                  id="3"
                  v-if="
                    $currentUserCan($permissions.PERM_VIEW_ANY_SUBSCRIPTIONS)
                  "
                >
                  <span slot="title">
                    <i class="fa fa-id-card" />
                    {{ $t("COMMON.SUBSCRIPTIONS") }}
                  </span>
                  <organization-view-subscriptions
                    :organization="organization"
                  />
                </tab-pane>

                <tab-pane
                  title="users"
                  id="4"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
                >
                  <span slot="title">
                    <i class="ni ni-single-02" />
                    {{ $t("COMMON.USERS") }}
                  </span>
                  <organization-view-users :organization="organization" />
                </tab-pane>

                <tab-pane
                  title="roles"
                  id="5"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ROLES)"
                >
                  <span slot="title">
                    <i class="ni ni-hat-3" />
                    {{ $t("COMMON.ROLES") }}
                  </span>
                  <organization-view-roles :organization="organization" />
                </tab-pane>

                <tab-pane
                  title="logs"
                  id="999"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
                >
                  <span slot="title">
                    <i class="fa fa-file" />
                    {{ $t("COMMON.LOGS") }}
                  </span>
                  <organization-view-logs :organization="organization" />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>

    <modal
      :show.sync="showSubscriptionAddModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <div class="col-12 text-center mb-2 mt-5">
        <h2>{{ $t("ORGANIZATIONS.SELECT_A_PACKAGE") }}</h2>
      </div>

      <form class="" @submit.prevent="generateSubscriptionInvoice">
        <base-input :label="$t('COMMON.PACKAGES')">
          <package-selector
            @packageChanged="
              (packageId) => (addSubscriptionPackageId = packageId)
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.package" />

        <div class="my-4">
          <base-button
            type="button"
            class="btn btn-sm btn-primary"
            native-type="submit"
            :disabled="loading"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading" />
            {{ $t("ORGANIZATIONS.ADD_SUBSCRIPTION") }}
          </base-button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import { Tabs, TabPane } from "@/components";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import PackageSelector from "@/components/PackageSelector.vue";
import ValidationError from "@/components/ValidationError.vue";
import defaultOrganization from "./defaultOrganization";
import OrganizationViewGlobal from "./partials/OrganizationViewGlobal.vue";
import OrganizationViewUsers from "./partials/OrganizationViewUsers.vue";
import OrganizationViewRoles from "./partials/OrganizationViewRoles.vue";
import OrganizationViewLogs from "./partials/OrganizationViewLogs.vue";
import OrganizationViewSubscriptions from "./partials/OrganizationViewSubscriptions.vue";
import OrganizationViewAuthorizations from "./partials/OrganizationViewAuthorizations.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    OrganizationViewGlobal,
    OrganizationViewUsers,
    OrganizationViewRoles,
    OrganizationViewLogs,
    OrganizationViewSubscriptions,
    OrganizationViewAuthorizations,
    PackageSelector,
    ValidationError,
  },

  mixins: [formMixin, requestErrorMixin],

  data() {
    return {
      organization: cloneDeep(defaultOrganization),
      loading: false,
      showSubscriptionAddModal: false,
      addSubscriptionPackageId: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("organizations/get", id);
        this.organization = this.$store.getters["organizations/organization"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editOrganization(row) {
      this.$router.push({
        name: "Edit Organization",
        params: { id: this.organization.id },
      });
    },

    async deleteOrganization(id) {
      const confirmation = await swal.fire({
        title: this.$t("ORGANIZATIONS.DELETE_THIS_ORGANIZATION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("organizations/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: this.$t("ORGANIZATIONS.ORGANIZATION_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async generateSubscriptionInvoice() {
      this.loading = true;
      try {
        const invoice = await this.$store.dispatch(
          "organizations/generateSubscriptionInvoice",
          {
            organizationId: this.organization.id,
            data: { package: this.addSubscriptionPackageId },
          }
        );

        this.$notify({
          type: "success",
          message: this.$t("ORGANIZATIONS.SUBSCRIPTION_INVOICE_ADDED"),
        });
        this.loading = false;

        this.$router.push({
          name: "View Invoice",
          params: { id: invoice.id },
        });
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
        this.setApiValidation(error.response.data.errors);
      }
    },

    goBack() {
      this.$router.push({ name: "List Organizations" });
    },
  },
};
</script>
