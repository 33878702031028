import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import files from "./modules/files-module";
// import alerts from "./modules/alerts-module";
import reset from "./modules/reset";
import profile from "./modules/profile-module";
import users from "./modules/users-module";
import roles from "./modules/roles-module";
import permissions from "./modules/permissions-module";
import organizations from "./modules/organizations-module";
import resellers from "./modules/resellers-module";
import products from "./modules/products-module";
import packages from "./modules/packages-module";
import invoices from "./modules/invoices-module";
import invoiceItems from "./modules/invoice-items-module";
import customers from "./modules/customers-module";
import logs from "./modules/logs-module";
import subscriptions from "./modules/subscriptions-module";
import payments from "./modules/payments-module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    files,
    // alerts,
    reset,
    profile,
    users,
    roles,
    permissions,
    organizations,
    resellers,
    products,
    invoices,
    invoiceItems,
    customers,
    logs,
    packages,
    subscriptions,
    payments,
  },
});
