<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <sidebar-item
          true
          :link="{
            name: $t('COMMON.DASHBOARD'),
            icon: 'ni ni-shop text-primary',
            path: '/dashboard',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_CUSTOMERS)"
          true
          :link="{
            name: $t('COMMON.CUSTOMERS'),
            icon: 'ni ni-circle-08 text-primary',
            path: '/customers',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_INVOICES)"
          true
          :link="{
            name: $t('COMMON.INVOICES'),
            icon: 'ni ni-single-copy-04 text-primary',
            path: '/invoices',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_PAYMENTS)"
          true
          :link="{
            name: $t('COMMON.PAYMENTS'),
            icon: 'ni ni-money-coins text-primary',
            path: '/payments',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SUBSCRIPTIONS)"
          true
          :link="{
            name: $t('COMMON.SUBSCRIPTIONS'),
            icon: 'fa fa-id-card text-primary',
            path: '/subscriptions',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_PRODUCTS)"
          true
          :link="{
            name: $t('COMMON.PRODUCTS'),
            icon: 'fa fa-cubes text-primary',
            path: '/products',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_PACKAGES)"
          true
          :link="{
            name: $t('COMMON.PACKAGES'),
            icon: 'ni ni-box-2 text-primary',
            path: '/packages',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_ORGANIZATIONS) &&
            !me.organization
          "
          true
          :link="{
            name: $t('COMMON.ORGANIZATIONS'),
            icon: 'ni ni-building text-primary',
            path: '/organizations',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_RESELLERS) &&
            !me.reseller
          "
          true
          :link="{
            name: $t('COMMON.RESELLERS'),
            icon: 'ni ni ni-shop text-primary',
            path: '/resellers',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_USERS)"
          true
          :link="{
            name: $t('COMMON.USERS'),
            icon: 'ni ni-single-02 text-primary',
            path: '/users',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_ROLES)"
          true
          :link="{
            name: $t('COMMON.ROLES'),
            icon: 'ni ni-hat-3 text-primary',
            path: '/roles',
          }"
        >
        </sidebar-item>

        <hr
          class="my-3"
          style="
            border-color: rgba(0, 0, 0, 0.1) currentcolor currentcolor;
            border-style: solid none none;
            border-width: 1px 0px 0px;
            border-image: none 100% / 1 / 0 stretch;
            min-width: 80%;
            overflow: visible;
            box-sizing: content-box;
            height: 0px;
          "
        />
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import { loadLanguageAsync } from "@/plugins/i18n";
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";
import Vuex from "vuex";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
  },
  mixins: [],
  data() {
    return {
      roles: [],
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  async mounted() {
    this.initScrollbar();
    try {
      await this.$store.dispatch("profile/me");
      const me = await this.$store.getters["profile/me"];
      if (this.$i18n.locale !== me.locale) {
        loadLanguageAsync(me.locale);
      }
    } catch (e) {
      if (e.response) {
        if ([401, 403].includes(e.response.status)) {
          this.$store.dispatch("logout");
        }
      } else {
        throw e;
      }
    }
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },
  watch: {
    me: {
      handler: function (val) {
        this.roles = val.roles.map((r) => r.name);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
body.g-sidenav-hidden {
  #purchase-button,
  #demo-button,
  #star-button,
  #docs-button {
    margin-left: 8px;
    margin-right: 8px;
    padding: 11px 0px;
  }
}
body.g-sidenav-show {
  #purchase-button,
  #demo-button,
  #star-button,
  #docs-button {
    margin-left: 20px;
    margin-right: 20px;
    padding: 11px 0px;
  }
}
</style>
