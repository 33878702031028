var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticClass:"\n        col-12\n        d-flex\n        justify-content-center justify-content-sm-between\n        flex-wrap\n      "},[_c('el-select',{staticClass:"select-primary pagination-select mb-2",attrs:{"placeholder":_vm.$t('COMMON.PER_PAGE')},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1),(_vm.$currentUser().is_staff)?_c('el-select',{attrs:{"placeholder":_vm.$t('USERS.IS_STAFF')},model:{value:(_vm.selectedIsStaff),callback:function ($$v) {_vm.selectedIsStaff=$$v},expression:"selectedIsStaff"}},[_c('el-option',{attrs:{"value":null,"label":_vm.$t('USERS.ALL_IS_STAFF')}}),_c('el-option',{attrs:{"value":true,"label":_vm.$t('COMMON.YES')}}),_c('el-option',{attrs:{"value":false,"label":_vm.$t('COMMON.NO')}})],1):_vm._e(),(
          !_vm.filterRole && _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ROLES)
        )?_c('role-selector',{on:{"roleChanged":function (roleId) { return (_vm.selectedRole = roleId); }}}):_vm._e(),(
          !_vm.filterOrganization &&
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
        )?_c('organization-selector',{on:{"organizationChanged":function (organizationId) { return (_vm.selectedOrganization = organizationId); }}}):_vm._e(),(
          !_vm.filterReseller &&
          !_vm.filterOrganization &&
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_RESELLERS)
        )?_c('reseller-selector',{on:{"resellerChanged":function (resellerId) { return (_vm.selectedReseller = resellerId); }}}):_vm._e(),_c('div',[_c('base-input',{attrs:{"type":"search","prepend-icon":"fas fa-search","placeholder":_vm.$t('COMMON.SEARCH'),"clearable":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)],1),_c('el-table',{staticClass:"table-responsive align-items-center table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.users},on:{"sort-change":_vm.sortChange}},[(_vm.loading)?_c('div',{attrs:{"slot":"empty"},slot:"empty"},[_c('img',{staticStyle:{"height":"100px","width":"100px"},attrs:{"src":"/img/loading.gif"}})]):_vm._e(),_c('el-table-column',{attrs:{"label":"","min-width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [(row.profile_image)?_c('img',{staticClass:"avatar rounded-circle mr-3",attrs:{"src":row.profile_image}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"width":"100px","label":_vm.$t('USERS.ACTIVE'),"prop":"active"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_c('icon-check',{attrs:{"checked":!!row.active}})],1)]}}])}),(_vm.$currentUser().is_staff)?_c('el-table-column',{attrs:{"width":"100px","label":_vm.$t('USERS.IS_STAFF'),"prop":"is_staff"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_c('icon-check',{attrs:{"checked":!!row.is_staff}})],1)]}}],null,false,123915209)}):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.NAME'),"prop":"firstname","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(((row.firstname) + " " + (row.lastname))))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.EMAIL'),"prop":"email","sortable":"custom"}}),(
          !_vm.filterRole && _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ROLES)
        )?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.ROLE'),"prop":"roles.name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [(row.roles[0])?_c('span',[_vm._v(" "+_vm._s(row.roles[0].name)+" ")]):_vm._e()]}}],null,false,3865387478)}):_vm._e(),(
          !_vm.filterOrganization &&
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
        )?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.ORGANIZATION'),"prop":"organization.name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(row.organization ? row.organization.name : null)+" ")])]}}],null,false,3222426871)}):_vm._e(),(
          !_vm.filterReseller &&
          !_vm.filterOrganization &&
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_RESELLERS)
        )?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.RESELLER'),"prop":"reseller.name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.reseller ? row.reseller.name : null))])]}}],null,false,1895905367)}):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.CREATED_AT'),"prop":"created_at","sortable":"custom"}}),_c('el-table-column',{attrs:{"min-width":"50px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return _c('div',{staticClass:"table-actions"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_USERS))?_c('el-tooltip',{attrs:{"content":_vm.$t('COMMON.VIEW'),"placement":"top"}},[_c('a',{staticClass:"table-action",staticStyle:{"cursor":"pointer"},attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.viewUser(row)}}},[_c('i',{staticClass:"fas fa-eye"})])]):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_EDIT_USERS))?_c('el-tooltip',{attrs:{"content":_vm.$t('COMMON.EDIT'),"placement":"top"}},[_c('a',{staticClass:"table-action",staticStyle:{"cursor":"pointer"},attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.editUser(row)}}},[_c('i',{staticClass:"fas fa-edit"})])]):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_DELETE_USERS))?_c('el-tooltip',{attrs:{"content":"Delete","placement":"top"}},[_c('a',{staticClass:"table-action table-action-delete",staticStyle:{"cursor":"pointer"},attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.deleteUser(row.id)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()],1)}}])})],1)],1),_c('div',{staticClass:"\n      mt-5\n      col-12\n      d-flex\n      justify-content-center justify-content-sm-between\n      flex-wrap\n    ",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", { from: _vm.total ? _vm.from + 1 : 0, to: _vm.to, of: _vm.total, }))+" "),(_vm.selectedRows.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("COMMON.X_LINES_SELECTED", { count: _vm.selectedRows.length }))+" ")]):_vm._e()])]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }