var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[(_vm.showIsStaff && _vm.$currentUser().is_staff)?_c('base-checkbox',{staticClass:"mb-3",on:{"change":function () {
        _vm.onFormChanged();
      }},model:{value:(_vm.user.is_staff),callback:function ($$v) {_vm.$set(_vm.user, "is_staff", $$v)},expression:"user.is_staff"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(_vm._s(_vm.$t("USERS.IS_STAFF")))])]):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.is_staff}}),(
      !_vm.user.is_staff &&
      _vm.showReseller &&
      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_RESELLERS)
    )?_c('base-input',{attrs:{"label":_vm.$t('COMMON.RESELLER'),"placeholder":_vm.$t('COMMON.RESELLER')}},[_c('reseller-selector',{attrs:{"reseller":_vm.user.reseller ? _vm.user.reseller.id : null,"filterable":true,"showAll":false,"allowNone":true},on:{"resellerChanged":function (resellerId) {
          _vm.user.reseller = { type: 'resellers', id: resellerId };
          _vm.user.organization = { type: 'organizations', id: null };
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.reseller}}),(
      !_vm.user.is_staff &&
      _vm.showOrganization &&
      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
    )?_c('base-input',{attrs:{"label":_vm.$t('COMMON.ORGANIZATION'),"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"filterReseller":_vm.user.reseller.id,"organization":_vm.user.organization ? _vm.user.organization.id : null,"filterable":true,"showAll":false,"allowNone":true},on:{"organizationChanged":function (organizationId) {
          _vm.user.organization = { type: 'organizations', id: organizationId };
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),_c('image-selector',{attrs:{"defaultImage":_vm.user.profile_image,"ressource_name":"users","ressource_id":_vm.user.id ? _vm.user.id : 0,"field":"profile_image"},on:{"imageChanged":function (file_url) {
        _vm.user.profile_image = file_url;
        _vm.onFormChanged();
      }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.attachment}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.FIRSTNAME')) + " (*)"),"placeholder":_vm.$t('COMMON.FIRSTNAME')},on:{"change":function () {
        _vm.onFormChanged();
      }},model:{value:(_vm.user.firstname),callback:function ($$v) {_vm.$set(_vm.user, "firstname", $$v)},expression:"user.firstname"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.firstname}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.LASTNAME')) + " (*)"),"placeholder":_vm.$t('COMMON.LASTNAME')},on:{"change":function () {
        _vm.onFormChanged();
      }},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.lastname}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.EMAIL')) + " (*)"),"placeholder":_vm.$t('COMMON.EMAIL')},on:{"change":function () {
        _vm.onFormChanged();
      }},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.email}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.PHONE')) + " (*)")}},[_c('phone-number-input',{attrs:{"phoneNumber":_vm.user.phone},on:{"phoneNumberChanged":function (phone) {
          _vm.user.phone = phone;
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.phone}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ROLES))?_c('base-input',{attrs:{"label":((_vm.$t('COMMON.ROLE')) + " (*)"),"placeholder":_vm.$t('COMMON.ROLE')}},[_c('role-selector',{attrs:{"role":_vm.user.roles.length ? _vm.user.roles[0].id : null,"filterable":true,"showAll":false,"filterReseller":_vm.user.reseller.id,"filterOrganization":_vm.user.organization.id},on:{"roleChanged":function (roleId) {
          _vm.user.roles[0].id = roleId;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.roles}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.LOCALE')) + " (*)"),"placeholder":_vm.$t('COMMON.LOCALE')}},[_c('locale-selector',{attrs:{"locale":_vm.user.locale},on:{"localeChanged":function (locale) {
          _vm.user.locale = locale;
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.locale}}),_c('base-input',{staticClass:"mb-3",attrs:{"type":"password","name":"new_password","prepend-icon":"fa fa-key","placeholder":_vm.$t('COMMON.PASSWORD'),"label":_vm.$t('COMMON.PASSWORD')},on:{"change":function () {
        _vm.onFormChanged();
      }},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.password}}),_c('base-input',{staticClass:"mb-3",attrs:{"type":"password","name":"confirm_password","autocomplete":"on","prepend-icon":"fa fa-key","placeholder":_vm.$t('COMMON.CONFIRM_PASSWORD'),"label":_vm.$t('COMMON.CONFIRM_PASSWORD')},on:{"change":function () {
        _vm.onFormChanged();
      }},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.password_confirmation}}),_c('h2',[_vm._v(_vm._s(_vm.$t("INVOICES.BILLING_ADDRESS")))]),_c('billing-informations-form',{attrs:{"billingInformationsData":_vm.user,"formErrors":_vm.formErrors},on:{"onChangeBillingInformations":_vm.billingInformationsChanged}}),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.user.id ? _vm.$t("USERS.EDIT_USER") : _vm.$t("USERS.ADD_USER"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }