<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-10">
              <h3 class="mb-0">
                {{ invoice.name }}
                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="editInvoice()"
                  v-if="invoice.status === INVOICE_STATUS_DRAFT"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="
                    invoice.status === INVOICE_STATUS_DRAFT &&
                    $currentUserCan($permissions.PERM_DELETE_INVOICES)
                  "
                  type="danger"
                  icon
                  size="sm"
                  @click="deleteInvoice()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-trash"></i>
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="sendInvoice()"
                  :disabled="loading"
                  v-if="invoice.items.length > 0"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-send"></i>
                    {{ $t("INVOICES.SEND") }}
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="generatePayment()"
                  :disabled="loading"
                  v-if="invoice.status === INVOICE_STATUS_SENT"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-money-coins"></i>
                    {{ $t("INVOICES.ADD_PAYMENT") }}
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="refundInvoice()"
                  :disabled="loading"
                  v-if="canRefundInvoice"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-money-coins"></i>
                    {{ $t("INVOICES.REFUND") }}
                  </span>
                </base-button>

                <base-button
                  type="danger"
                  icon
                  size="sm"
                  @click="cancelInvoice()"
                  :disabled="loading"
                  v-if="canCancelInvoice"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-fat-remove"></i>
                    {{ $t("INVOICES.CANCEL") }}
                  </span>
                </base-button>
              </h3>
            </div>
            <div class="col-2 text-right">
              <base-button
                @click="goBack()"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
        </template>
        <div>
          <div
            class="
              col-12
              justify-content-center justify-content-sm-between
              flex-wrap
            "
          >
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="global"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="true">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GLOBAL") }}
                  </span>
                  <invoice-view-global :invoice="invoice" />
                </tab-pane>

                <tab-pane title="details" id="2" :active="false">
                  <span slot="title">
                    <i class="ni ni-bullet-list-67" />
                    {{ $t("COMMON.DETAILS") }}
                  </span>
                  <invoice-view-details
                    :invoice="invoice"
                    @invoiceItemsUpdated="get"
                  />
                </tab-pane>

                <tab-pane
                  title="payments"
                  id="3"
                  :active="false"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PAYMENTS)"
                >
                  <span slot="title">
                    <i class="ni ni-money-coins" />
                    {{ $t("COMMON.PAYMENTS") }}
                  </span>
                  <invoice-view-payments :invoice="invoice" />
                </tab-pane>

                <tab-pane
                  title="logs"
                  id="999"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
                >
                  <span slot="title">
                    <i class="fa fa-file" />
                    {{ $t("COMMON.LOGS") }}
                  </span>
                  <invoice-view-logs :invoice="invoice" />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import defaultInvoice from "./defaultInvoice";
import { cloneDeep } from "lodash";

import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import InvoiceViewGlobal from "./partials/InvoiceViewGlobal.vue";
import InvoiceViewDetails from "./partials/InvoiceViewDetails.vue";
import InvoiceViewPayments from "./partials/InvoiceViewPayments.vue";
import InvoiceViewLogs from "./partials/InvoiceViewLogs.vue";
import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DRAFT,
  INVOICE_STATUS_SENT,
  INVOICE_STATUS_PAID,
} from "@/constants/invoices";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    InvoiceViewGlobal,
    InvoiceViewDetails,
    InvoiceViewPayments,
    InvoiceViewLogs,
  },

  mixins: [requestErrorMixin],

  data() {
    return {
      loading: false,
      invoice: cloneDeep(defaultInvoice),
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
      INVOICE_STATUS_SENT: INVOICE_STATUS_SENT,
      INVOICE_STATUS_CANCELED: INVOICE_STATUS_CANCELED,
      INVOICE_STATUS_PAID: INVOICE_STATUS_PAID,
    };
  },

  computed: {
    canRefundInvoice() {
      if (this.invoice.status === INVOICE_STATUS_CANCELED) {
        if (this.invoice.total_remaining_payment > 0) {
          return true;
        }
        if (this.invoice.total_paied.toFixed(2) > 0) {
          return true;
        }
      }
      return false;
    },
    canCancelInvoice() {
      if (
        this.invoice.status === INVOICE_STATUS_CANCELED ||
        this.invoice.status === INVOICE_STATUS_DRAFT
      ) {
        return false;
      }
      if (
        this.invoice.status === INVOICE_STATUS_SENT ||
        this.invoice.status === INVOICE_STATUS_PAID
      ) {
        return true;
      }
      return false;
    },
  },

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("invoices/get", id);
        this.invoice = this.$store.getters["invoices/invoice"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editInvoice(row) {
      this.$router.push({
        name: "Edit Invoice",
        params: { id: this.invoice.id },
      });
    },

    async generatePayment(row) {
      this.$router.push({
        name: "Add Payment",
        params: {},
        query: { invoice_code: this.invoice.code },
      });
    },

    async sendInvoice() {
      try {
        this.loading = true;
        await this.$store.dispatch("invoices/send", this.invoice.id);
        this.invoice = this.$store.getters["invoices/invoice"];
        this.$notify({
          type: "success",
          message: this.$t("INVOICES.INVOICE_SENT"),
        });
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async cancelInvoice() {
      const confirmation = await swal.fire({
        title: this.$t("INVOICES.CANCEL_INVOICE_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation.value) {
        return;
      }

      try {
        this.loading = true;
        await this.$store.dispatch("invoices/cancel", this.invoice.id);
        this.invoice = this.$store.getters["invoices/invoice"];
        this.$notify({
          type: "success",
          message: this.$t("INVOICES.INVOICE_CANCELED"),
        });
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async refundInvoice() {
      try {
        this.loading = true;
        await this.$store.dispatch("invoices/refund", this.invoice.id);
        this.invoice = this.$store.getters["invoices/invoice"];
        this.$notify({
          type: "success",
          message: this.$t("INVOICES.INVOICE_REFUNDED"),
        });
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async deleteInvoice() {
      const confirmation = await swal.fire({
        title: this.$t("INVOICES.DELETE_THIS_INVOICE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("invoices/destroy", this.invoice.id);
          await this.goBack();
          this.$notify({
            type: "success",
            message: this.$t("INVOICES.INVOICE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    goBack() {
      this.$router.push({ name: "List Invoices" });
    },
  },
};
</script>
