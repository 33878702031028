var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":((_vm.$t('COMMON.ORGANIZATION')) + " (*)"),"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"allowNone":true,"organization":_vm.invoice.organization.id,"filterable":true,"showAll":false},on:{"organizationChanged":function (organizationId) {
              _vm.invoice.organization.id = organizationId;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":((_vm.$t('INVOICES.EXPIRATION_TIME')) + " (*)"),"placeholder":_vm.$t('INVOICES.EXPIRATION_TIME')}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
            allowInput: true,
            minDate: 'today',
            locale: _vm.$flatPickrLocale(),
          }},on:{"on-change":function () {
              _vm.onFormChanged();
            }},model:{value:(_vm.invoice.expiration_time),callback:function ($$v) {_vm.$set(_vm.invoice, "expiration_time", $$v)},expression:"invoice.expiration_time"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.expiration_time}})],1)]),_c('h2',[_vm._v(_vm._s(_vm.$t("INVOICES.BILLING_ADDRESS")))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.TYPE'),"placeholder":_vm.$t('COMMON.TYPE')}},[_c('el-select',{attrs:{"name":"Type","prepend-icon":"fas fa-calendar","disabled":!!_vm.invoice.id},on:{"change":_vm.changeRecipientType},model:{value:(_vm.invoice.recipient.type),callback:function ($$v) {_vm.$set(_vm.invoice.recipient, "type", $$v)},expression:"invoice.recipient.type"}},[_c('el-option',{attrs:{"value":null,"label":_vm.$t('COMMON.NONE')}}),(
              _vm.invoice.organization.id && !_vm.$currentUserIsLevelOrganization()
            )?_c('el-option',{attrs:{"value":"users","label":_vm.$t('COMMON.USERS')}}):_vm._e(),(_vm.invoice.organization.id)?_c('el-option',{attrs:{"value":"customers","label":_vm.$t('COMMON.CUSTOMERS')}}):_vm._e(),(
              !_vm.invoice.organization.id && !_vm.$currentUserIsLevelOrganization()
            )?_c('el-option',{attrs:{"value":"organizations","label":_vm.$t('COMMON.ORGANIZATIONS')}}):_vm._e()],1)],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.invoice.recipient.type)?_c('base-input',{attrs:{"label":((_vm.$t(
          ("INVOICES.SELECT_" + (_vm.invoice.recipient.type.toUpperCase()))
        )) + " (*)")}},[_c('el-select',{attrs:{"filterable":"","name":"recipient_id","prepend-icon":"fas fa-calendar","remote":"","remote-method":_vm.getRecipients,"loading":_vm.loadingRecipients,"disabled":!!_vm.invoice.id},on:{"change":_vm.changeRecipient},model:{value:(_vm.invoice.recipient.id),callback:function ($$v) {_vm.$set(_vm.invoice.recipient, "id", $$v)},expression:"invoice.recipient.id"}},_vm._l((_vm.all_recipients),function(el){return _c('el-option',{key:el.id,attrs:{"value":el.id,"label":el.name}})}),1)],1):_vm._e()],1)]),_c('billing-informations-form',{attrs:{"billingInformationsData":_vm.invoice,"formErrors":_vm.formErrors},on:{"onChangeBillingInformations":_vm.billingInformationsChanged}}),_c('h2',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("COMMON.NOTE")))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"type":"textarea","placeholder":_vm.$t('COMMON.NOTE')}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.invoice.excerpt),expression:"invoice.excerpt"}],staticClass:"form-control",attrs:{"rows":"5"},domProps:{"value":(_vm.invoice.excerpt)},on:{"change":function () {
              _vm.onFormChanged();
            },"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.invoice, "excerpt", $event.target.value)}}})]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1)]),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.invoice.id ? _vm.$t("INVOICES.EDIT_INVOICE") : _vm.$t("INVOICES.ADD_INVOICE"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }