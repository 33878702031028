<template>
  <el-select
    :placeholder="$t('COMMON.RESELLER')"
    v-model="resellerModel"
    @change="resellerChanged"
    :filterable="filterable"
    :multiple="multiple"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL_RESELLERS')">
    </el-option>
    <el-option
      v-for="reseller in resellers"
      :key="reseller.id"
      :value="reseller.id"
      :label="reseller.name"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "reseller-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    reseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
  },

  data() {
    return {
      resellerModel: this.reseller,
      resellers: {},
    };
  },

  setup() {},

  created() {
    this.getResellers();
  },

  methods: {
    async getResellers() {
      try {
        await this.$store.dispatch("resellers/list", { sort: "name" });
        const resellersArr = await this.$store.getters["resellers/list"];
        this.resellers = {};
        resellersArr.forEach((reseller) => {
          this.resellers[reseller.id] = reseller;
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    resellerChanged(reseller) {
      this.$emit("resellerChanged", reseller);
    },
  },

  watch: {
    reseller(reseller) {
      this.resellerModel = reseller;
    },
  },
};
</script>
