<template>
  <modal :show.sync="showModal" modal-classes="modal-secondary" size="lg">
    <form class="" @submit.prevent="">
      <base-input :label="`${$t('INVOICES.ITEM_TYPE')}`" v-if="!invoiceItem.id">
        <el-select
          name="Type"
          v-model="invoiceItemModel.invoiceable.type"
          prepend-icon="fas fa-calendar"
          @change="changeInvoiceableType"
        >
          <el-option :value="null" :label="$t('COMMON.NONE')" />
          <el-option
            v-if="invoice.organization"
            value="products"
            :label="$t('COMMON.PRODUCT')"
          />
          <el-option
            v-if="!$currentUserIsLevelOrganization() && !invoice.organization"
            value="packages"
            :label="$t('COMMON.PACKAGE')"
          />
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.invoiceable" />

      <base-input
        :label="`${$t(
          `INVOICES.SELECT_${invoiceItemModel.invoiceable.type.toUpperCase()}`
        )} (*)`"
        v-if="invoiceItemModel.invoiceable.type && !invoiceItem.id"
      >
        <el-select
          filterable
          name="invoiceable_id"
          v-model="invoiceItemModel.invoiceable.id"
          prepend-icon="fas fa-calendar"
          @change="changeInvoiceable"
        >
          <el-option
            v-for="el in all_invoiceables"
            :key="el.id"
            :value="el.id"
            :label="el.name"
          >
          </el-option>
        </el-select>
      </base-input>
      <validation-error
        v-if="invoiceItemModel.invoiceable.type && !invoiceItem.id"
        :errors="apiValidationErrors.invoiceable"
      />

      <base-input
        :label="`${$t('COMMON.CODE')} (*)`"
        :placeholder="$t('COMMON.CODE')"
        v-model="invoiceItemModel.code"
        input-classes="form-control-alternative"
        v-if="!invoiceItemModel.invoiceable.type"
      >
      </base-input>
      <validation-error
        v-if="!invoiceItemModel.invoiceable.type"
        :errors="apiValidationErrors.code"
      />

      <base-input
        :label="$t('COMMON.EXCERPT')"
        :placeholder="$t('COMMON.EXCERPT')"
        input-classes="form-control-alternative"
      >
        <textarea
          class="form-control"
          rows="3"
          v-model="invoiceItemModel.excerpt"
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />

      <base-input
        :label="`${$t('COMMON.UNIT_PRICE')} (*)`"
        :placeholder="$t('COMMON.UNIT_PRICE')"
        v-model="invoiceItemModel.unit_price"
        type="number"
        step="0.0001"
        input-classes="form-control-alternative"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.unit_price" />

      <base-input
        :label="`${$t('COMMON.QUANTITY')} (*)`"
        :placeholder="$t('COMMON.QUANTITY')"
        v-model="invoiceItemModel.quantity"
        type="number"
        step="1"
        input-classes="form-control-alternative"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.quantity" />

      <taxes-selector
        :label="$t('COMMON.TAXES')"
        :taxes="invoiceItemModel.taxes"
        @taxesChanged="(taxes) => (invoiceItemModel.taxes = taxes)"
      />
      <validation-error :errors="apiValidationErrors.taxes" />

      <base-input
        :label="$t('COMMON.DISCOUNT_PERCENT')"
        :placeholder="$t('COMMON.DISCOUNT_PERCENT')"
        v-model="invoiceItemModel.discount"
        type="number"
        step="0.0001"
        input-classes="form-control-alternative"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.discount" />
    </form>

    <template slot="footer">
      <button
        v-if="!invoiceItemModel.id"
        type="submit"
        class="btn btn-primary"
        @click="submitInvoiceItemForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("INVOICES.ADD_INVOICE_ITEM") }}
      </button>

      <base-button
        v-if="invoiceItemModel.id"
        native-type="submit"
        type="primary"
        class="new-invoice-item--add"
        @click="submitInvoiceItemForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("INVOICES.UPDATE_INVOICE_ITEM") }}
      </base-button>

      <base-button
        v-if="invoiceItemModel.id"
        type="danger"
        @click="deleteInvoiceItem(invoiceItemModel.id)"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("INVOICES.DELETE_INVOICE_ITEM") }}
      </base-button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closeInvoiceItemModal"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import ValidationError from "@/components/ValidationError.vue";
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { cloneDeep } from "lodash";
import TaxesSelector from "@/components/TaxesSelector.vue";
import defaultInvoiceItem from "../defaultInvoiceItem";

export default {
  name: "invoice-view-details-invoice-item-form",

  components: {
    ValidationError,
    TaxesSelector,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["invoice", "invoiceItem", "showModal", "loading"],

  data() {
    const me = this.$store.getters["profile/me"];
    return {
      all_invoiceables: [],
      invoiceItemModel: cloneDeep(defaultInvoiceItem),
      me: me,
    };
  },

  computed: {},

  methods: {
    closeInvoiceItemModal() {
      this.$emit("onCloseInvoiceItemModal", true);
    },

    submitInvoiceItemForm() {
      const invoiceItemData = cloneDeep(this.invoiceItemModel);
      invoiceItemData.unit_price = parseFloat(invoiceItemData.unit_price);
      invoiceItemData.quantity = parseInt(invoiceItemData.quantity, 10);
      invoiceItemData.discount = parseFloat(invoiceItemData.discount);
      if (!invoiceItemData.invoiceable.id) {
        invoiceItemData.invoiceable = null;
      }

      this.$emit("onSubmitInvoiceItemForm", invoiceItemData);
    },

    deleteInvoiceItem(invoiceItemId) {
      this.$emit("onDeleteInvoiceItem", invoiceItemId);
    },

    async getInvoiceables(type) {
      try {
        let params = { sort: "name" };
        if (type === "packages") {
          if (this.invoice.recipient.type === "organizations") {
            params = {
              ...params,
              filter: { reseller: this.invoice.recipient.reseller_id },
            };
          }
        } else {
          params = {
            ...params,
            filter: { organization: this.invoice.organization.id },
          };
        }
        await this.$store.dispatch(type + "/list", params);
        this.all_invoiceables = await this.$store.getters[type + "/dropdown"];
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async changeInvoiceableType(type) {
      if (!type) {
        return;
      }
      await this.getInvoiceables(type);
      this.invoiceItemModel.invoiceable.id = null;
    },

    async changeInvoiceable(id) {
      const invoiceables = await this.$store.getters[
        this.invoiceItemModel.invoiceable.type + "/list"
      ];
      const invoiceable = invoiceables.find((item) => item.id === id);
      this.invoiceItemModel.unit_price = invoiceable.price;

      this.invoiceItemModel.code = invoiceable.code;
      this.invoiceItemModel.excerpt = invoiceable.denomination;
      this.invoiceItemModel.taxes = invoiceable.taxes;
    },
  },

  mounted() {},

  watch: {
    invoiceItem(invoiceItem) {
      if (invoiceItem) {
        this.invoiceItemModel = cloneDeep(invoiceItem);
      }
      this.resetApiValidation();
    },
  },
};
</script>
