import moment from "moment";

export default {
  type: "invoices",
  expiration_time: moment().add(30, "days").format("YYYY-MM-DD"),
  billing_firstname: null,
  billing_lastname: null,
  billing_country: "Canada",
  billing_state: "Quebec",
  billing_city: null,
  billing_zipcode: null,
  billing_address: null,
  billing_email: null,
  excerpt: "",
  discounts: [],
  relationshipNames: ["recipient", "organization"],
  recipient: {
    type: null,
    id: null,
  },
  organization: {
    type: "organizations",
    id: null,
  },
  items: [],
};
