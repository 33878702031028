import service from "@/store/services/files-service";

export const state = () => ({
  url: null,
});

export const mutations = {
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  upload({ commit }, { image, ressource_name, field, ressouce_id = 0 }) {
    return service
      .upload(image, ressource_name, ressouce_id, field, this.$axios)
      .then((url) => {
        commit("SET_URL", url);
      });
  },
};

const getters = {
  url: (state) => state.url,
};

const files = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default files;
