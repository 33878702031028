<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <div
      class="row"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <div class="col">
        <base-input
          :label="`${$t('COMMON.ORGANIZATION')} (*)`"
          :placeholder="$t('COMMON.ORGANIZATION')"
        >
          <organization-selector
            :allowNone="true"
            :organization="invoice.organization.id"
            :filterable="true"
            :showAll="false"
            @organizationChanged="
              (organizationId) => {
                invoice.organization.id = organizationId;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.organization" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('INVOICES.EXPIRATION_TIME')} (*)`"
          :placeholder="$t('INVOICES.EXPIRATION_TIME')"
        >
          <flat-picker
            :config="{
              allowInput: true,
              minDate: 'today',
              locale: $flatPickrLocale(),
            }"
            class="form-control datepicker"
            v-model="invoice.expiration_time"
            @on-change="
              () => {
                onFormChanged();
              }
            "
          >
          </flat-picker>
        </base-input>
        <validation-error :errors="apiValidationErrors.expiration_time" />
      </div>
    </div>

    <h2>{{ $t("INVOICES.BILLING_ADDRESS") }}</h2>

    <div class="row">
      <div class="col">
        <base-input :label="$t('COMMON.TYPE')" :placeholder="$t('COMMON.TYPE')">
          <el-select
            name="Type"
            v-model="invoice.recipient.type"
            prepend-icon="fas fa-calendar"
            @change="changeRecipientType"
            :disabled="!!invoice.id"
          >
            <el-option :value="null" :label="$t('COMMON.NONE')" />
            <el-option
              v-if="
                invoice.organization.id && !$currentUserIsLevelOrganization()
              "
              value="users"
              :label="$t('COMMON.USERS')"
            />
            <el-option
              v-if="invoice.organization.id"
              value="customers"
              :label="$t('COMMON.CUSTOMERS')"
            />
            <el-option
              v-if="
                !invoice.organization.id && !$currentUserIsLevelOrganization()
              "
              value="organizations"
              :label="$t('COMMON.ORGANIZATIONS')"
            />
          </el-select>
        </base-input>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t(
            `INVOICES.SELECT_${invoice.recipient.type.toUpperCase()}`
          )} (*)`"
          v-if="invoice.recipient.type"
        >
          <el-select
            filterable
            name="recipient_id"
            v-model="invoice.recipient.id"
            prepend-icon="fas fa-calendar"
            @change="changeRecipient"
            remote
            :remote-method="getRecipients"
            :loading="loadingRecipients"
            :disabled="!!invoice.id"
          >
            <el-option
              v-for="el in all_recipients"
              :key="el.id"
              :value="el.id"
              :label="el.name"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
    </div>

    <billing-informations-form
      :billingInformationsData="invoice"
      :formErrors="formErrors"
      @onChangeBillingInformations="billingInformationsChanged"
    />

    <h2 class="mt-5">{{ $t("COMMON.NOTE") }}</h2>
    <div class="row">
      <div class="col">
        <base-input type="textarea" :placeholder="$t('COMMON.NOTE')">
          <textarea
            class="form-control"
            rows="5"
            v-model="invoice.excerpt"
            @change="
              () => {
                onFormChanged();
              }
            "
          >
          </textarea>
        </base-input>
        <validation-error :errors="apiValidationErrors.excerpt" />
      </div>
    </div>

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          invoice.id ? $t("INVOICES.EDIT_INVOICE") : $t("INVOICES.ADD_INVOICE")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import BillingInformationsForm from "@/components/BillingInformationsForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    flatPicker,
    BillingInformationsForm,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["invoiceData", "formErrors", "loading"],

  data() {
    return {
      all_recipients: [],
      invoice: { ...this.invoiceData },
      loadingRecipients: false,
    };
  },

  created() {
    this.refreshRecipient();
  },

  methods: {
    async handleSubmit() {
      this.invoice.expiration_time = moment(
        this.invoice.expiration_time
      ).toISOString();
      this.$emit("invoiceSubmitted", this.invoice);
    },

    async getRecipients(query = null) {
      try {
        let params = {
          sort: "firstname",
          filter: {
            ...(query ? { search: query } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.invoice.recipient.type === "organizations") {
          params = { ...params, sort: "name" };
        } else {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.invoice.organization.id,
            },
          };
        }

        await this.$store.dispatch(
          this.invoice.recipient.type + "/list",
          params
        );
        this.all_recipients = await this.$store.getters[
          this.invoice.recipient.type + "/dropdown"
        ];
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async changeRecipientType(type) {
      this.onFormChanged();
      if (!type) {
        return;
      }
      await this.getRecipients();
      this.invoice.recipient.id = null;
    },

    async changeRecipient(id) {
      this.onFormChanged();
      const recipients = await this.$store.getters[
        this.invoice.recipient.type + "/list"
      ];
      const recipient = recipients.find((item) => item.id === id);
      const billingInformations = Object.keys(recipient)
        .filter((key) => key.startsWith("billing_"))
        .reduce((obj, key) => {
          obj[key] = recipient[key];
          return obj;
        }, {});
      this.invoice = { ...this.invoice, ...billingInformations };
    },

    billingInformationsChanged(billingInformations) {
      this.invoice = { ...this.invoice, ...billingInformations };
      this.onFormChanged();
    },

    async refreshRecipient() {
      if (this.invoice.recipient.id) {
        await this.$store.dispatch(this.invoice.recipient.type + "/list", {
          filter: { id: this.invoice.recipient.id },
          page: {
            number: 1,
            size: 10,
          },
        });
        this.all_recipients = await this.$store.getters[
          this.invoice.recipient.type + "/dropdown"
        ];
      }
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    invoiceData(invoiceData) {
      if (invoiceData) {
        this.invoice = {
          ...this.invoice,
          ...cloneDeep(invoiceData),
        };
        if (!this.invoice.organization) {
          this.invoice.organization = {
            type: "organizations",
            id: null,
          };
        }
        if (!this.invoice.recipient) {
          this.invoice.recipient = {
            type: "users",
            id: null,
          };
        }
        this.refreshRecipient();
      }
    },
  },
};
</script>
