export default {
  type: "resellers",
  name: null,
  excerpt: null,
  email: null,
  phone: null,
  address: null,
  logo: null,
  relationshipNames: ["owner"],
  owner: {
    type: "users",
    id: null,
  },
};
