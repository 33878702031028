export default {
  PERM_VIEW_MODULE_PAYMENTS: "view module payments",
  PERM_VIEW_ANY_PAYMENTS: "view any payments",
  PERM_VIEW_PAYMENTS: "view payments",
  PERM_CREATE_PAYMENTS: "create payments",
  PERM_VIEW_MODULE_SUBSCRIPTIONS: "view module subscriptions",
  PERM_VIEW_ANY_SUBSCRIPTIONS: "view any subscriptions",
  PERM_VIEW_SUBSCRIPTIONS: "view subscriptions",
  PERM_CREATE_SUBSCRIPTIONS: "create subscriptions",
  PERM_EDIT_SUBSCRIPTIONS: "edit subscriptions",
  PERM_DELETE_SUBSCRIPTIONS: "delete subscriptions",
  PERM_VIEW_MODULE_PACKAGES: "view module packages",
  PERM_VIEW_ANY_PACKAGES: "view any packages",
  PERM_VIEW_PACKAGES: "view packages",
  PERM_CREATE_PACKAGES: "create packages",
  PERM_EDIT_PACKAGES: "edit packages",
  PERM_DELETE_PACKAGES: "delete packages",
  PERM_VIEW_MODULE_CUSTOMERS: "view module customers",
  PERM_VIEW_ANY_CUSTOMERS: "view any customers",
  PERM_VIEW_CUSTOMERS: "view customer",
  PERM_CREATE_CUSTOMERS: "create customer",
  PERM_EDIT_CUSTOMERS: "edit customer",
  PERM_DELETE_CUSTOMERS: "delete customer",
  PERM_VIEW_MODULE_INVOICES: "view module invoices",
  PERM_VIEW_ANY_INVOICES: "view any invoices",
  PERM_VIEW_INVOICES: "view invoice",
  PERM_CREATE_INVOICES: "create invoice",
  PERM_EDIT_INVOICES: "edit invoice",
  PERM_DELETE_INVOICES: "delete invoice",
  PERM_VIEW_MODULE_ORGANIZATIONS: "view module organizations",
  PERM_VIEW_ANY_ORGANIZATIONS: "view any organizations",
  PERM_VIEW_ORGANIZATIONS: "view organization",
  PERM_CREATE_ORGANIZATIONS: "create organization",
  PERM_EDIT_ORGANIZATIONS: "edit organization",
  PERM_EDIT_OWN_ORGANIZATIONS: "edit own organization",
  PERM_DELETE_ORGANIZATIONS: "delete organization",
  PERM_VIEW_MODULE_RESELLERS: "view module resellers",
  PERM_VIEW_ANY_RESELLERS: "view any resellers",
  PERM_VIEW_RESELLERS: "view resellers",
  PERM_CREATE_RESELLERS: "create resellers",
  PERM_EDIT_RESELLERS: "edit resellers",
  PERM_EDIT_OWN_RESELLERS: "edit own resellers",
  PERM_DELETE_RESELLERS: "delete resellers",
  PERM_VIEW_MODULE_USERS: "view module users",
  PERM_VIEW_ANY_USERS: "view any users",
  PERM_VIEW_USERS: "view users",
  PERM_CREATE_USERS: "create users",
  PERM_EDIT_USERS: "edit users",
  PERM_DELETE_USERS: "delete users",
  PERM_VIEW_MODULE_PRODUCTS: "view module products",
  PERM_VIEW_ANY_PRODUCTS: "view any products",
  PERM_VIEW_PRODUCTS: "view products",
  PERM_CREATE_PRODUCTS: "create products",
  PERM_EDIT_PRODUCTS: "edit products",
  PERM_DELETE_PRODUCTS: "delete products",
  PERM_VIEW_MODULE_ROLES: "view module roles",
  PERM_VIEW_ANY_ROLES: "view any roles",
  PERM_VIEW_ROLES: "view roles",
  PERM_CREATE_ROLES: "create roles",
  PERM_EDIT_ROLES: "edit roles",
  PERM_DELETE_ROLES: "delete roles",
  PERM_VIEW_ANY_PERMISSIONS: "view any permissions",
  PERM_VIEW_PERMISSIONS: "view permissions",
  PERM_VIEW_ANY_LOGS: "view any logs",
  PERM_VIEW_LOGS: "view logs",
};
