<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ `${customer.company_name}` }} -
        {{ $t("COMMON.INVOICES") }}
      </span>
    </div>

    <invoice-list-table
      :filterCustomer="customer.id"
      :filterRecipient="{ type: 'customers', id: customer.id }"
    />
  </div>
</template>

<script>
import InvoiceListTable from "../../InvoiceManagement/partials/InvoiceListTable.vue";

export default {
  name: "customer-view-users",

  components: { InvoiceListTable },

  props: ["customer"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    customer(customer) {},
  },
};
</script>
