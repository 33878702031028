<template>
  <el-select
    :placeholder="$t('COMMON.INVOICE')"
    v-model="invoiceModel"
    @change="invoiceChanged"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getInvoices"
    :loading="loading"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL_INVOICES')">
    </el-option>
    <el-option
      v-for="invoice in invoices"
      :key="invoice.id"
      :value="invoice.id"
      :label="invoice.code"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "invoice-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    invoice: {
      type: String,
      default: null,
      description: "Invoice id",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterStatus: {
      type: String,
      default: null,
      description: "Invoice status",
    },
    filterCode: {
      type: String,
      default: null,
      description: "Invoice code",
    },
  },

  data() {
    return {
      invoiceModel: this.invoice,
      invoices: {},
      loading: false,
    };
  },

  setup() {},

  created() {
    if (this.invoice) {
      this.getInvoices(this.invoice.name);
    } else {
      this.getInvoices("");
    }
  },

  methods: {
    async getInvoices(query) {
      try {
        this.loading = true;
        let params = {
          sort: "-created_at",
          filter: {
            ...(query ? { code: query } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterReseller) {
          params = {
            ...params,
            filter: { ...params.filter, reseller: this.filterReseller },
          };
        }
        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.filterStatus) {
          params = {
            ...params,
            filter: { ...params.filter, status: this.filterStatus },
          };
        }
        if (this.filterCode) {
          params = {
            ...params,
            filter: { ...params.filter, code: this.filterCode },
          };
        }

        await this.$store.dispatch("invoices/list", params);
        const invoicesArr = await this.$store.getters["invoices/list"];
        this.invoices = {};
        invoicesArr.forEach((invoice) => {
          this.invoices[invoice.id] = invoice;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    invoiceChanged(invoice) {
      this.$emit("invoiceChanged", invoice, this.invoices[invoice]);
    },
  },

  watch: {
    invoice(invoice) {
      this.invoiceModel = invoice;
    },
    filterOrganization() {
      this.getInvoices();
    },
    filterReseller() {
      this.getInvoices();
    },
    filterStatus() {
      this.getInvoices();
    },
    filterCode() {
      this.getInvoices();
    },
  },
};
</script>
