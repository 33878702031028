<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">{{ $t("PAYMENTS.PAYMENTS_LIST") }}</h3>
            </div>
            <div class="col-6 text-right">
              <base-button
                type="primary"
                icon
                size="sm"
                @click="addPayment()"
                v-if="$currentUserCan($permissions.PERM_CREATE_PAYMENTS)"
              >
                <span class="btn-inner--icon">
                  <i class="fas fa-plus"></i>
                </span>
                <span class="btn-inner--text">{{ $t("PAYMENTS.ADD_PAYMENT") }}</span>
              </base-button>
            </div>
          </div>
        </template>

        <payment-list-table />
      </card>
    </div>
  </div>
</template>
<script>
import { Button } from "element-ui";
import PaymentListTable from "./partials/PaymentListTable.vue";

export default {
  layout: "DashboardLayout",

  components: {
    PaymentListTable,
    [Button.name]: Button,
  },

  mixins: [],

  computed: {},

  watch: {},

  methods: {
    addPayment() {
      this.$router.push({ name: "Add Payment" });
    },
  },
};
</script>
