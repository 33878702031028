<template>
  <div class="row mb-5">
    <el-table
      class="table-responsive align-items-center table-flush"
      header-row-class-name="thead-light"
      :data="invoice.items"
    >
      <el-table-column :label="$t('COMMON.CODE')" prop="code">
        <template v-slot="{ row }">
          <div v-if="row.invoiceable">
            <a href="#" @click.prevent="goToInvoiceable(row.invoiceable)">
              {{ row.code }}<br />
              <span class="text-muted">
                {{ row.excerpt }}
              </span>
            </a>
          </div>
          <div v-if="!row.invoiceable">
            {{ row.code }}<br />
            <span class="text-muted">
              {{ row.excerpt }}
            </span>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('COMMON.UNIT_PRICE')" prop="unit_price">
        <template v-slot="{ row }"> ${{ row.unit_price }} </template>
      </el-table-column>

      <el-table-column :label="$t('COMMON.QUANTITY')" prop="quantity" />

      <el-table-column :label="$t('COMMON.SUBTOTAL')" prop="discount">
        <template v-slot="{ row }">
          ${{ row.pricing.subtotal.toFixed(2) }}
        </template>
      </el-table-column>

      <el-table-column :label="$t('COMMON.DISCOUNT')" prop="discount">
        <template v-slot="{ row }">
          ${{ row.pricing.discount_amount.toFixed(2) }}
          <span class="text-muted">({{ row.discount }}%)</span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('COMMON.TAXES')">
        <template v-slot="{ row }">
          ${{ row.pricing.taxes.total.toFixed(2) }}
        </template>
      </el-table-column>

      <el-table-column :label="$t('COMMON.TOTAL')">
        <template v-slot="{ row }">
          ${{ row.pricing.total.toFixed(2) }}
        </template>
      </el-table-column>

      <el-table-column min-width="50px" align="center">
        <div slot-scope="{ row }" class="table-actions">
          <el-tooltip
            :content="$t('COMMON.EDIT')"
            placement="top"
            v-if="invoice.status === INVOICE_STATUS_DRAFT"
          >
            <a
              type="text"
              @click="openInvoiceItemModal(row)"
              class="table-action"
              data-toggle="tooltip"
              style="cursor: pointer"
            >
              <i class="fas fa-edit"></i>
            </a>
          </el-tooltip>
        </div>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import { INVOICE_STATUS_DRAFT } from "@/constants/invoices";

export default {
  name: "invoice-view-details-invoice-item-table",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin],

  props: ["invoice"],

  data() {
    return {
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
    };
  },

  computed: {},

  methods: {
    openInvoiceItemModal(invoiceItem) {
      this.$emit("onOpenInvoiceItemModal", invoiceItem);
    },

    async goToInvoiceable(invoiceable) {
      if (invoiceable.type === "registrations") {
        this.$router.push({
          name: "View Registration",
          params: { id: invoiceable.id },
        });
      } else if (invoiceable.type === "event-kiosks") {
        await this.$store.dispatch("eventKiosks/get", invoiceable.id);
        const kiosk = this.$store.getters["eventKiosks/eventKiosk"];
        this.$router.push({
          name: "View Event",
          params: { id: kiosk.event.id },
        });
      }
    },
  },

  mounted() {},

  watch: {},
};
</script>
