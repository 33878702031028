<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <organization-selector
        :organization="product.organization.id"
        :filterable="true"
        :showAll="false"
        @organizationChanged="
          (organizationId) => {
            product.organization.id = organizationId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <image-selector
      :label="$t('COMMON.PICTURE')"
      :defaultImage="product.picture"
      ressource_name="products"
      :ressource_id="product.id ? product.id : 0"
      field="picture"
      @imageChanged="
        (file_url) => {
          product.picture = file_url;
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.attachment" />

    <gallery-selector
      :label="$t('COMMON.GALLERY')"
      :defaultGallery="product.gallery"
      ressource_name="products"
      :ressource_id="product.id ? product.id : 0"
      field="gallery"
      @galleryChanged="
        (gallery_urls) => {
          product.gallery = gallery_urls;
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.attachment" />

    <base-input
      :label="`${$t('COMMON.NAME')} (*)`"
      :placeholder="$t('COMMON.NAME')"
      v-model="product.name"
      @change="onFormChanged()"
    />
    <validation-error :errors="apiValidationErrors.name" />

    <base-input
      type="textarea"
      :label="$t('COMMON.EXCERPT')"
      :placeholder="$t('COMMON.EXCERPT')"
    >
      <textarea
        class="form-control"
        rows="3"
        v-model="product.excerpt"
        @change="onFormChanged()"
      >
      </textarea>
    </base-input>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <base-input
      :label="`${$t('COMMON.PRICE')} (*)`"
      :placeholder="$t('COMMON.PRICE')"
      v-model="product.price"
      @change="onFormChanged()"
    />
    <validation-error :errors="apiValidationErrors.price" />

    <taxes-selector
      :label="$t('COMMON.TAXES')"
      :taxes="product.taxes"
      @taxesChanged="
        (taxes) => {
          product.taxes = taxes;
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.taxes" />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          product.id ? $t("PRODUCTS.EDIT_PRODUCT") : $t("PRODUCTS.ADD_PRODUCT")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import models from "@/constants/models";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import ImageSelector from "@/components/ImageSelector.vue";
import GallerySelector from "@/components/GallerySelector.vue";
import TaxesSelector from "@/components/TaxesSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    ImageSelector,
    GallerySelector,
    TaxesSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["productData", "formErrors", "loading"],

  data() {
    return {
      product: { ...this.productData },
      permissions: [],
      permissionsModel: {},
      models: models,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      this.$emit("productSubmitted", this.product);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    productData(productData) {
      if (productData) {
        this.product = {
          ...this.product,
          ...cloneDeep(productData),
        };
      }
    },
  },
};
</script>
