<template>
  <el-select
    :placeholder="$t('COMMON.USER')"
    v-model="userModel"
    @change="userChanged"
    :filterable="filterable"
    :multiple="multiple"
    remote
    :remote-method="getUsers"
    :loading="loading"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL_USERS')">
    </el-option>
    <el-option
      v-for="user in users"
      :key="user.id"
      :value="user.id"
      :label="`${user.firstname} ${user.lastname} - ${user.email}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "user-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    user: {
      type: Object,
      default: null,
      description: "User id",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
    filterResellerNull: {
      type: Boolean,
      default: false,
      description: "Only display users without reseller",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterOrganizationNull: {
      type: Boolean,
      default: null,
      description: "Only display users without organization",
    },
  },

  data() {
    return {
      userModel: this.user,
      users: {},
      loading: false,
    };
  },

  setup() {},

  created() {
    if (this.user) {
      this.getUsers(this.user.firstname);
    } else {
      this.getUsers("");
    }
  },

  methods: {
    async getUsers(query) {
      try {
        this.loading = true;
        let params = {
          sort: "firstname",
          filter: {
            ...(query ? { search: query } : {}),
          },
          page: {
            number: 1,
            size: 20,
          },
        };
        if (this.filterReseller) {
          params = {
            ...params,
            filter: { ...params.filter, reseller: this.filterReseller },
          };
        }
        if (this.filterResellerNull) {
          params = {
            ...params,
            filter: { ...params.filter, reseller: null },
          };
        }
        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.filterOrganizationNull) {
          params = {
            ...params,
            filter: { ...params.filter, organization: null },
          };
        }

        await this.$store.dispatch("users/list", params);
        const usersArr = await this.$store.getters["users/list"];
        this.users = {};
        usersArr.forEach((user) => {
          this.users[user.id] = user;
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    userChanged(user) {
      this.$emit("userChanged", user);
    },
  },

  watch: {
    user(user) {
      this.userModel = user.id;
      this.getUsers(this.user.firstname);
    },
    filterReseller() {
      this.getUsers();
    },
    filterResellerNull() {
      this.getUsers();
    },
    filterOrganization() {
      this.getUsers();
    },
    filterOrganizationNull() {
      this.getUsers();
    },
  },
};
</script>
