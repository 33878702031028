<template>
  <div class="form-group">
    <label class="form-control-label"> {{ label }} </label>
    <div v-for="(tax, key) in taxesModel" :key="key" class="row">
      <div class="col-5 d-none">
        <label class="form-control-label"> {{ $t("COMMON.TYPE") }} </label>
        <el-select
          :label="$t('COMMON.TYPE')"
          :placeholder="$t('COMMON.TYPE')"
          v-model="tax.type"
          @change="taxesChanged"
        >
          <el-option
            :value="TAX_TYPE_PERCENTAGE"
            :label="$t('COMMON.TAX_TYPE_PERCENTAGE')"
          />
          <el-option
            :value="TAX_TYPE_AMOUNT"
            :label="$t('COMMON.TAX_TYPE_AMOUNT')"
          />
        </el-select>
      </div>
      <div class="col-5">
        <base-input
          :label="$t('COMMON.NAME')"
          :placeholder="$t('COMMON.NAME')"
          v-model="tax.name"
          @change="taxesChanged"
        />
      </div>
      <div class="col-5">
        <base-input
          :label="$t('COMMON.RATE')"
          :placeholder="$t('COMMON.RATE')"
          v-model="tax.value"
          @change="taxesChanged"
        />
      </div>
      <div class="col-2">
        <label class="form-control-label mb-4"></label>
        <base-button
          type="button"
          class="form-control btn btn-sm col-12 btn-danger"
          style="border: 1px solid #ccc"
          @click.prevent="() => removeTax(key)"
        >
          <i class="fas fa-times" />
        </base-button>
      </div>
    </div>
    <base-button
      type="button"
      class="btn btn-sm col-12"
      style="border: 1px solid #ccc"
      @click.prevent="addTax"
    >
      <i class="fas fa-plus fa-2x" />
    </base-button>
  </div>
</template>

<script>
import { Option, Select } from "element-ui";
import { cloneDeep } from "lodash";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { TAX_TYPE_PERCENTAGE, TAX_TYPE_AMOUNT } from "@/constants/taxes";

export default {
  name: "taxes-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseInput,
  },

  props: {
    taxes: {
      type: Array,
      default: null,
      description: "Taxes array",
    },
    label: {
      type: String,
      default: null,
      description: "Label of field",
    },
  },

  data() {
    return {
      taxesModel: this.taxes,
      TAX_TYPE_PERCENTAGE: TAX_TYPE_PERCENTAGE,
      TAX_TYPE_AMOUNT: TAX_TYPE_AMOUNT,
    };
  },

  setup() {},

  created() {},

  methods: {
    addTax() {
      this.taxesModel.push({ name: null, value: 0, item: TAX_TYPE_PERCENTAGE });
      this.taxesChanged();
    },
    removeTax(key) {
      this.taxesModel = this.taxesModel.filter((_, idx) => idx !== key);
      this.taxesChanged();
    },
    taxesChanged() {
      const taxesData = this.taxesModel.map((item) => {
        const value = parseFloat(item.value);
        return {
          name: item.name,
          value: value ? value : null,
          type: item.type ? item.type : TAX_TYPE_PERCENTAGE,
        };
      });
      this.$emit("taxesChanged", taxesData);
    },
  },

  watch: {
    taxes(taxes) {
      this.taxesModel = cloneDeep(taxes);
    },
  },
};
</script>
