<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <h1>{{ invoice.code }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ invoice.created_at | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("INVOICES.EXPIRATION_TIME") }}</dt>
          <dd class="col-sm-8">
            {{ invoice.expiration_time | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <router-link
              v-if="invoice.organization"
              :to="`/organizations/view/${invoice.organization.id}`"
            >
              {{ `${invoice.organization.name}` }}
            </router-link>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.RECIPIENT") }}</dt>
          <dd class="col-sm-8">
            <router-link
              v-if="invoice.recipient"
              :to="`/${invoice.recipient.type}/view/${invoice.recipient.id}`"
            >
              {{
                invoice.recipient.type === "organizations"
                  ? `${invoice.recipient.name}`
                  : `${invoice.recipient.firstname} ${invoice.recipient.lastname}`
              }}
            </router-link>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
          <dd class="col-sm-8">
            <invoice-status-badge :invoice="invoice" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TOTAL") }}</dt>
          <dd class="col-sm-8" v-if="invoice.pricing">
            <span> {{ $formatCurrency(invoice.pricing.total) }} </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TOTAL_PAIED") }}</dt>
          <dd class="col-sm-8">
            <span>
              {{
                $formatCurrency(invoice.total_paied ? invoice.total_paied : 0)
              }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4" v-if="invoice.excerpt">
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd class="col-sm-8" v-if="invoice.excerpt">
            <div v-html="invoice.excerpt"></div>
          </dd>
        </dl>

        <div class="col-12 text-center mb-2 mt-5">
          <h2>{{ $t("INVOICES.BILLING_ADDRESS") }}</h2>
        </div>

        <billing-informations-view
          :billingInformations="invoice"
          :type="invoice.customer_type"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InvoiceStatusBadge from "./InvoiceStatusBadge.vue";
import BillingInformationsView from "@/components/BillingInformationsView.vue";

export default {
  name: "invoice-view-global",

  components: {
    InvoiceStatusBadge,
    BillingInformationsView,
  },

  props: ["invoice"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    invoice(invoice) {},
  },
};
</script>
